.login {

    &-title {

        h3 {
            @apply font-body font-extra mb-4;
        }
    }
}

.register {

    &-form {
        @apply bg-white bg-opacity-80 rounded-lg;
        backdrop-filter: blur(35px);
    }

    &-image {

        img {
            @apply w-full;
        }
    }
}