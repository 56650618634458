@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    html {
        @apply text-sm scroll-smooth;
    }

    body {
        @apply text-black bg-grayLight leading-normal font-body text-[13px] lg:text-sm;
    }

    h1, h2, h3, h4, h5, h6 {
        @apply font-title leading-tight;
    }

    h1 {
        @apply text-2xl lg:text-4xl;
    }

    h2 {
        @apply text-xl lg:text-3xl;
    }

    h3 {
        @apply text-lg lg:text-2xl;
    }

    h4 {
        @apply text-rg lg:text-xl;
    }

    h5 {
        @apply text-rg lg:text-lg;
    }

    h6 {
        @apply text-sm;
    }

    label {
        @apply inline-block mb-1;
    }

    .collapse {
        visibility: unset !important;
    }

    .container {
        @apply px-3 lg:px-7;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type=number] {
        -moz-appearance:textfield; /* Firefox */
    }

    :focus-visible {
        @apply outline-none;
    }
}