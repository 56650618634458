.empty {

    &-image {

        img {
            @apply mx-auto h-[200px];
        }
    }

    &-body {

        p {
            @apply mt-2 mx-auto lg:w-[30%];
        }
    }
}