.quantity {
    @apply h-8 border-b border-b-graySemi font-bold text-center;

    &-button {
        @apply absolute flex items-center justify-center top-0 w-[14px] h-full cursor-pointer;

        .product-cart & {
            @apply w-[10px];

            svg {
                @apply w-[10px];
            }
        }

        &.down {
            @apply left-0;
        }

        &.up {
            @apply right-0;
        }
    }
}