$primary: #FF2929;
$primary-invert: lighten($primary, 100%);

$secondary: #FAB600;
$secondary-invert: darken($secondary, 100%);

$light: #fff;
$light-invert: $secondary;

$dark: #354856;
$dark-invert: lighten($dark, 100%);

$colors: (
    "primary": ($primary, $primary-invert),
    "secondary": ($secondary, $secondary-invert),
    "light": ($light, $light-invert),
    "dark": ($dark, $dark-invert),
);

.btn {
    @apply inline-flex items-center justify-center h-10 lg:h-12 px-6 lg:text-rg border border-transparent rounded-full font-bold cursor-pointer;
    text-align: center;
    outline: 0;
    user-select: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    
    svg {
        @apply h-4;
    }

    &-sm {
        @apply h-9 px-4 text-sm;

        svg {
            height: 14px;
        }
    }

    &-lg {
        @apply lg:h-14 lg:px-8 lg:text-lg;
    }

    @each $name, $pair in $colors {
        $color: nth($pair, 1);
        $color-invert: nth($pair, 2);
        
        &-#{$name} {
            color: $color-invert;
            background-color: $color #{!important};
            border-color: $color;

            &:hover {
                @apply bg-yellow border-white #{!important};
            }

            svg {
                fill: $color-invert;
            }
        }
    }

    @each $name, $pair in $colors {
        $color: nth($pair, 1);
        $color-invert: nth($pair, 2);

        &-outline-#{$name} {
            background-color: transparent;
            border-color: $color;
            color: $color;

            svg {
                fill: $color;
            }

            &:hover {
                @apply bg-yellow text-black border-black;

                svg {
                    @apply fill-black;
                }
            }
        }

        &-ghost-#{$name} {
            @apply h-[unset] px-0;
            background-color: transparent;
            border-color: transparent;
            color: $color;

            svg {
                fill: $color;
            }
        }
    }
}