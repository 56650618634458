.dropdown {
    @apply relative;

    &.active {
        
        .dropdown {

            &-toggle {

                .icon {
                    @apply rotate-180;
                    transition: transform .25s;
                }
            }

            &-item {
                @apply block;
            }
        }
    }

    &-toggle {
        @apply flex items-center cursor-pointer;

        .image {

            img {
                @apply h-4;
            }
        }
    }

    &-item {
        @apply bg-white absolute hidden top-8 min-w-[100px] h-auto rounded-sm overflow-hidden z-50;

        &--end {
            @apply lg:right-0;
        }

        a {
            @apply text-black hover:text-green;

            .image {
    
                img {
                    @apply h-4;
                }
            }
        }

        .navbar--fixed & {
            @apply top-[38px];
        }
    }
}