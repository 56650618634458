.status {

    &::before {
        @apply relative inline-block left-0 w-4 h-4 mr-2 bg-no-repeat bg-contain;
        content: '';
    }

    &--pending {

        &::before {
            background-image: url('../images/icon-pending.svg');
        }
    }

    &--process {

        &::before {
            background-image: url('../images/icon-processing.svg');
        }
    }

    &--shipped {

        &::before {
            background-image: url('../images/icon-truck.svg');
        }
    }

    &--completed {

        &::before {
            background-image: url('../images/icon-complete.svg');
        }
    }

    &--cancel {

        &::before {
            background-image: url('../images/icon-cancel.svg');
        }
    }

    &--failed {

        &::before {
            background-image: url('../images/icon-failed.svg');
        }
    }

    &--refund {

        &::before {
            background-image: url('../images/icon-refund.svg');
        }
    }
}