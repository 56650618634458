.section {

    &--transaction-detail, &--search, &--stray {
        padding-top: 132px;

        @include media-breakpoint-up(lg) {
            padding-top: 56px+80px;
        }
    }

    &--store {

        @include media-breakpoint-down(lg) {
            background-image: none !important;
        }
    }

    &--login {
        padding-top: 132px;

        @include media-breakpoint-up(lg) {
            padding-top: 56px+80px;
        }
    }

    &-absolute {

        @include media-breakpoint-down(lg) {
            @apply hidden;
        }

        .image-1 {
            @apply absolute lg:-right-12 lg:w-[200px];
            top: 80px * 2;
        }

        .image-2 {
            @apply absolute lg:-bottom-[115px];

            @include media-breakpoint-up(lg) {
                left: -225px;
                width: 500px;
            }
        }
    }

    &-dialog {
        @apply px-3 lg:px-7;
    }

    &-title {

        .section--maps & {
            @apply mx-auto lg:w-[500px];
        }

        .section--certify & {
            @apply mx-auto lg:w-[650px];
        }

        h2 {
            @apply text-xl lg:text-7xl;
            
            .section--about & {
                @apply text-red;
            }

            .section--products &, .section--news & {
                @apply text-white;
            }

            .section--maps &, .section--certify & {
                @apply lg:text-3xl;
            }
        }

        p {
            @apply lg:text-rg;

            .section--about & {
                @apply uppercase mb-2 lg:mb-4;
                letter-spacing: 5px;
            }
        }
    }

    &-body {

        .post {

            &-gallery {

                .play {

                    &:hover {

                        .play-icon {
                            @apply bg-yellow;

                            svg {
                                @apply fill-black;
                            }
                        }
                    }

                    &-icon {
                        @apply bg-red mb-2;

                        svg {
                            @apply fill-white;
                        }
                    }
                }

                .images {

                    > div {
                        @apply rounded-lg overflow-hidden will-change-transform;

                        img {
                            @apply object-center object-cover;
                        }
                        
                        &:nth-of-type(1), &:nth-of-type(3) {

                            @include media-breakpoint-only(md) {
                                margin-top: -94px * .5;
                            }
                            
                            @include media-breakpoint-up(lg) {
                                margin-top: -94px;
                            }
                        }
                    }
                }

                .certify {

                    img {
                        @apply mx-auto text-center;
                    }
                }
            }
        }

        .map {
            
            .mapTooltip {
                @apply bg-white absolute p-2 rounded-sm shadow-md overflow-hidden z-30;
            }

            &-tooltip {
                @apply flex items-center;

                p {
                    @apply pl-2;
                }

                img {
                    @apply h-3;
                }
            }
        }

        .section--store & {

            .store {

                &-list {
    
                    h3 {
                        @apply mb-4;
                    }
                }
            }
        }
    }
}