.timeline {

    @include media-breakpoint-down(lg) {
        background-image: none !important;
    }

    @include media-breakpoint-up(lg) {
        background-position: 0 65%;
    }

    &-grid {

        .timeline-item {
            
            &:nth-of-type(even) {

                img {
                    @apply lg:order-2;
                }

                .timeline-item__body {
                    @apply lg:order-1 lg:mr-19;
                }
            }

            &:nth-of-type(odd) {

                .timeline-item__body {
                    @apply lg:ml-19;
                }
            }
        }
    }

    &-item {

        img {
            @apply mx-auto w-[150px] lg:w-[300px];
        }

        &__body {
            @apply bg-white rounded-[32px] overflow-hidden;
            box-shadow: 0px 16px 32px rgba($color: #000000, $alpha: .1);

            h2 {
                @apply font-body font-bold lg:text-10xl text-red;
            }

            @include media-breakpoint-down(lg) {
                @apply mt-4;
            }
        }
    }
}