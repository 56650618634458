.jumbotron {
    @apply lg:h-[400px] flex items-center;
    padding-top: 132px;

    @include media-breakpoint-up(lg) {
        padding-top: 56px+80px;
    }

    &--product, &--news {
        height: unset;
    }

    &--news {
        @apply block;
    }

    &-body {
        
        @include media-breakpoint-down(lg) {
            @apply py-16;
        }

        .jumbotron--news & {
            @apply py-0;
        }

        h2 {
            @apply font-body font-extra lg:text-8xl;

            .jumbotron--page & {
                @apply text-xl;
            }
        }

        .page {
            @apply lg:text-[18px];
        }

        .jumbotron--news & {
            @apply aspect-square lg:aspect-[26/9] overflow-hidden;

            img {
                @apply w-full h-full object-cover object-center;
            }
        }
    }
}