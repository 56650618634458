.form {

    &-group {
        @apply mb-6 lg:mb-8;
    }

    &-submit {

        .wrap-captcha {
            @apply flex justify-center;
            transform: scale(.8);
        }
    }

    &-input, &-textarea, &-select {
        @apply text-black font-bold lg:text-rg border-graySemi w-full px-3 py-2 rounded-sm transition ease-in-out focus:border-green focus:ring-green #{!important};

        &__material {
            @apply text-white bg-transparent px-0 py-1 border-r-0 border-l-0 border-t-0 border-b border-b-white rounded-none placeholder:text-white focus:border-b-yellow focus:ring-0 #{!important};
        }
    }

    &-material {
        @apply relative;

        label {
            @apply absolute top-1/2 left-0 block pointer-events-none mb-0;
            transition: .1s ease-out;
            transform-origin: left top;
            transform: translateY(-50%);
        }

        input, select, textarea {
            @apply text-black text-lg bg-transparent w-full border-b border-b-black outline-0 placeholder:text-black focus:border-b-yellow focus:ring-0;
            transition: 0.1s ease-out;

            .contact-form & {
                @apply border-b-white #{!important};
            }
        }

        input, select {
            @apply h-12;
        }

        input, textarea {

            &:focus, &:valid {

                + label {
                    @apply top-0 text-xs;
                    transform: translateY(-50%);
                }
            }

            &:not(:placeholder-shown) {

                + label {
                    @apply top-0 text-xs;
                    transform: translateY(-50%);
                }
            }

            .contact-form & {
                @apply text-white #{!important};
            }
        }

        textarea {

            &:focus, &:valid {

                + label {
                    @apply -top-1 text-xs;
                    transform: translateY(-50%);
                }
            }

            ~ label {
                @apply top-0;
            }
        }

        select {
            
            &:focus, &:valid {

                ~ label {
                    @apply top-0 text-xs;
                    transform: translateY(-50%);
                }
            }

            .contact-form & {
                @apply text-white #{!important};
                
                option {
                    @apply text-black;
                }
            }
        }
    }

    &-bordered {
        @apply bg-transparent w-full h-8 border-b border-b-graySemi;
    }

    &-sizing {

        input[type='radio'] {
            @apply absolute block top-0 left-0 right-0 appearance-none;
            border: none;
            outline: none;

            &:checked {

                ~ label {
                    @apply bg-red;
                }
            }

            ~ label {
                @apply h-8 px-4 text-rg font-bold rounded-sm;
                background-color: rgba(26, 26, 26, .3);
            }
        }
    }

    &-choosen {

        input[type='radio'] {
            @apply absolute block top-0 left-0 appearance-none;
            border: none;
            outline: none;

            &:checked {

                ~ label {

                    .dots {
                        
                        &::after {
                            @apply opacity-100;
                        }
                    }
                }
            }

            ~ label {
                @apply inline-flex items-center cursor-pointer mb-0;

                .dots {
                    @apply relative inline-flex items-center justify-center w-4 h-4 bg-transparent border border-red rounded-full overflow-hidden;

                    &::after {
                        content: '';
                        @apply absolute block bg-red w-3 h-3 opacity-0 rounded-full transition ease-in-out;
                    }
                }

                .accordion--payment & {
                    @apply sm:items-start;

                    .dots {
                        @apply sm:mt-4;
                    }
                }
            }

        }
    }

    &-file {

        input[type="file"] {
            @apply absolute block top-0 left-0 appearance-none opacity-0;
            border: none;
            outline: none;

            ~ label {
                @apply relative flex items-center justify-center mb-0 w-[175px] h-[175px] rounded-full overflow-hidden cursor-pointer;

                .dots {
                    @apply absolute inline-block w-8 h-8 bg-contain bg-no-repeat;
                    background-image: url(../images/image-edit.png);
                }
            }
        }
    }
}