.modal {

    &-dialog {
        @apply min-w-[calc(100%-32px)] lg:min-w-[650px];

        .modal--store & {
            @apply lg:min-w-[900px];
        }

        .modal--alert & {
            @apply lg:min-w-[500px];
        }
    }

    &-content {

        .modal--store & {
            @apply lg:h-[calc(50vh+100px)];
        }
    }

    &-header {
        @apply absolute -top-14 right-0 lg:-top-10 lg:-right-10;

        [data-bs-dismiss="modal"] {
            @apply bg-white flex items-center justify-center w-7 h-7 rounded-full overflow-hidden cursor-pointer;
        }
    }

    &-body {
        @apply p-8;
        
        .content {

            .modal--cart &, .modal--alert & {

                &-detail {
                    @apply font-bold text-lg lg:text-xl leading-tight;
                }
            }

            .modal--store & {

                &-image {

                    img {
                        @apply mx-auto;
                    }
                }
            }

            &-slider {
                @apply hidden opacity-0 transition ease-in-out;

                &.open {
                    @apply block opacity-100;
                }

                .item {

                    &-image {
                        @apply aspect-[3/4] lg:h-[200px] mx-auto overflow-hidden;

                        > img {
                            @apply w-full h-full object-cover object-center;
                        }
                    }
                }
            }
        }
    }
}