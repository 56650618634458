.product {

    &-info {

        &__desc {

            .title {

                > h2 {
                    @apply mb-4 lg:text-4xl;
                }

                p {
                    @apply lg:text-rg;
                }
            }

            .sizing {
                
                > label {
                    @apply mb-3 font-bold;
                }
            }

            .pricing {

                &-info, &-quantity {

                    > label {
                        @apply mb-3 font-bold;
                    }
                }

                &-info {

                    &__detail {

                        > h6 {
                            @apply mb-2 font-body font-bold lg:text-xl;
                        }

                        .discount {

                            span {
                                @apply font-normal text-graySemi line-through;
                            }
                        }
                    }
                }
            }

            .actions {

                &-wishlist {

                    .icon {

                        &.checked {

                            .text {
                                
                                &::after {
                                    content: 'My Wishlist';
                                }
                            }
                        }

                        .text {
                            @apply pl-2;

                            &::after {
                                content: 'Add to Wishlist';
                                @apply relative inline-block;
                            }
                        }
                    }
                }
            }
        }
    }

    &-video {
        @apply mx-auto lg:w-[900px] overflow-hidden;

        .play {
            @apply absolute top-1/2 left-1/2;
            transform: translate(-50%, -50%);

            &:hover {

                .play-icon {
                    @apply bg-yellow;

                    svg {
                        @apply fill-black;
                    }
                }
            }

            &-icon {
                @apply bg-red mb-2;

                svg {
                    @apply fill-white;
                }
            }
        }
    }

    &-cart {

        &__action {

            .item {

                &-column {
                    @apply font-bold text-rg;

                    > p {
                        @apply w-15;
                    }

                    > h6 {
                        @apply flex-1 pl-2 font-body font-bold text-xl text-right;
                    }
                }
            }
        }
    }

    &-checkout {

        &__form {
            @apply p-4 lg:py-6 lg:px-12 text-rg;

            h6 {
                @apply mb-6 font-body font-extra text-rg;
            }

            .currently {

                &-target {
                    @apply h-0 opacity-0 overflow-hidden;
                    transition: height .5s cubic-bezier(0, 1, 0, 1);

                    &.show {
                        @apply h-full opacity-100;
                        transition: height .5s ease-in-out;
                    }
                }
            }

            .liner {

                &::before {
                    content: '';
                    @apply bg-[#DEDEDE] absolute top-1/2 left-0 w-full;
                    height: 1px;
                    transform: translateY(-50%);
                }

                span {
                    @apply bg-white relative px-4;
                }
            }

            .newest {

                &-form {
                    
                    &__submit {
                        @apply mt-6 pt-6 lg:mt-10 lg:pt-10 border-t border-t-[#DEDEDE];

                        > p {
                            @apply mb-6 lg:mb-10;
                        }
                    }
                }
            }
        }

        &__item {
            @apply sticky p-6;
            top: 56px+80px;

            .summary {

                &-total {
                    @apply mt-4 pt-4 border-t border-t-[#DEDEDE];

                    p {
                        @apply font-bold text-rg;
                    }

                    h6 {
                        @apply font-body font-bold lg:text-xl;
                    }
                }

                &-point {

                    p, h6 {
                        @apply font-body font-bold text-rg;
                    }
                }
            }
        }
    }

    &-related {

        .card--related {

            &:nth-of-type(even) {

                .card {

                    &-image {
                        @apply -rotate-12;
                    }
                }
            }

            &:nth-of-type(odd) {

                .card {

                    &-image {
                        @apply rotate-12;
                    }
                }
            }
        }
    }

    &-catalog {

        > div {

            &:nth-of-type(odd) {

                .media {

                    &-image {

                        img {
                            @apply -rotate-12;
                        }
                    }
                }
            }

            &:nth-of-type(even) {

                .media {

                    &-image {

                        img {
                            @apply rotate-12;
                        }
                    }
                }
            }
        }
    }
}