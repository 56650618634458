.profile {

    &-menu {
        @apply flex-nowrap lg:flex-wrap whitespace-nowrap lg:whitespace-normal;

        @include media-breakpoint-down(lg) {
            overflow-x: scroll;
        }

        .active {
            @apply text-lg lg:text-xl text-black;
        }
    }

    &-body {

        &__content {

            p, h6 {
                @apply text-rg;
            }
        }

        &__image {

            @include media-breakpoint-down(lg) {
                @apply mx-auto;
            }

            img {
                @apply object-cover object-center w-full h-full;
            }
        }

        .transaction {

            &-title {

                h5 {
                    @apply font-body font-extra;
                }
            }

            &-order {

                &__content {

                    .item {

                        p {
                            @apply mb-1 text-graySemi;
                        }
    
                        h6 {
                            @apply font-body font-bold lg:text-lg;
                        }
    
                        &::after {
                            @apply bg-yellow absolute inline-block lg:top-0 lg:right-0 lg:w-[1px] lg:h-full;
                            content: '';
                        }

                        &:last-child {

                            &::after {
                                @apply hidden;
                            }
                        }
                    }
                }
            }

            &-summary {

                &__content {

                    p {
                        @apply relative lg:w-[250px] text-graySemi;

                        &::after {
                            content: ':';
                            @apply absolute inline-block top-0 right-0;
                        }
                        
                        @include media-breakpoint-down(lg) {
                            @apply mb-1;
                        }
                    }

                    h6 {
                        @apply font-body leading-normal lg:text-rg;
                    }

                    .item {

                        &--summary {

                            p, h6 {
                                @apply lg:text-lg;
                            }

                            h6 {
                                @apply font-bold;
                            }
                        }
                    }
                }
            }

            &-action {
                @apply lg:pt-14 border-t border-t-gray;
            }
        }
    }
}