.floating {
    @apply bottom-4;

    @screen sm {
        @apply left-3;
    }

    &__dialog {

        .social {
            box-shadow: 0 8px 24px rgba($color: #000000, $alpha: .05);

            @include media-breakpoint-down(lg) {
                @apply h-14 mr-2;
            }

            > a {
                @apply block my-0 lg:my-2 fill-red;

                @include media-breakpoint-down(lg) {
                    @apply mx-4;
                }

                > svg {
                    @apply w-5 h-5 lg:w-4 lg:h-4;
                }
            }
        }

        .call {

            &-link {

                &:hover {

                    img {
                        
                        @include media-breakpoint-up(lg) {
                            transform: scale(1.5);
                        }
                    }

                    .call-link__text {
                        @apply text-sm mr-3;
                        width: 142px;
                    }
                }

                img {
                    @apply h-14 lg:h-10;
                }

                &__text {
                    @apply h-10;
                    font-size: 0;
                    width: 0;
                    transition: font-size .3s, width .3s, margin .3s;
                }
            }
        }
    }
}