.navbar {
    @apply bg-white bg-opacity-30 rounded-b-xl;
    backdrop-filter: blur(15px);

    &-toggle {
        @apply w-8 h-8 border border-black rounded-sm;
    }

    &-menu {
        @apply lg:text-lg;

        @include media-breakpoint-down(md) {
            @apply w-full;
        }

        &__logo {
            @apply lg:order-3;
        }

        &__list {

            @include media-breakpoint-down(lg) {
                @apply hidden -my-1;

                > div {
                    @apply py-1;
                }
            }

            @for $i from 4 through 7 {

                &:nth-child(#{$i}) {
                    @apply lg:order-#{$i};
                }
            }

            &-item {
                @apply bg-transparent px-3 py-2 rounded-rg transition ease-in-out;

                .active & {
                    @apply bg-red text-yellow;
                }

                &:hover {
                    @apply bg-red text-yellow;
                }
            }
        }
    }

    .search-form {

        input {
            @apply pr-5 #{!important};
        }

        &__submit {
            @apply w-4 h-full;
        }
    }
}