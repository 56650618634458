.media {
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &--news {

        &:hover {

            .media {

                &-image {

                    img {
                        @apply scale-110;
                    }
                }

                &-body {

                    > h4 {
                        @apply text-yellow;
                    }
                }
            }
        }
    }

    &--catalog {
        @apply relative overflow-hidden;

        &-big {
            @apply lg:col-span-2;

            .media {

                &-absolute {

                    img {

                        &:nth-child(1) {
                            @apply top-20 lg:top-1/2 -left-[50px] lg:-left-[100px] h-20 lg:h-[200px];
                            
                            @include media-breakpoint-up(lg) {
                                transform: translateY(-50%);
                            }
                        }
        
                        &:nth-child(2) {
                            @apply bottom-0 lg:bottom-12 -right-[85px] lg:-right-[140px] h-[calc(150px)] lg:h-[250px];
                        }
                    }
                }
            }
        }

        &-default {

            &:nth-of-type(even) {

                .media {

                    &-absolute {

                        img {
                            @apply -bottom-[25px] lg:-bottom-10 -left-[120px] lg:-left-[200px];
        
                            @include media-breakpoint-down(lg) {
                                @apply h-[150px];
                            }
                        }
                    }
                }
            }

            &:nth-of-type(odd) {

                .media {

                    &-absolute {

                        img {
                            @apply -bottom-[25px] lg:-bottom-[40px] -right-[125px] lg:-right-[185px];

                            @include media-breakpoint-down(lg) {
                                @apply h-[200px];
                            }
                        }
                    }
                }
            }
        }
    }

    &--jumbotron {
        @apply relative;
    }

    &-dialog {

        .media--catalog & {
            @apply relative items-center lg:h-[530px];
        }

        .media--catalog-default & {
            @apply lg:px-12;
        }
    }

    &-image {

        .media--news & {
            @apply w-[100px] lg:w-[165px];

            img {
                @apply w-full h-full object-cover object-center;
                transition: transform .5s ease-in-out;
            }
        }

        .media--catalog & {

            img {
                @apply sm:w-1/2 w-[275px] mx-auto;
                filter: drop-shadow(0 0 30px rgba(255, 255, 255, .6));
            }
        }

        .media--catalog-big & {

            img {
                @apply lg:w-[375px];
            }
        }

        .media--jumbotron & {

            img {
                @apply sm:w-1/2 w-[300px] mx-auto -rotate-12;
                filter: drop-shadow(0 0 30px rgba(255, 255, 255, .6));
            }
        }

        .media--cart & {
            @apply sm:w-[75px] w-[150px] overflow-hidden;

            img {
                @apply w-full h-full object-cover object-center;
            }
        }

        .media--checkout & {
            @apply w-[100px] overflow-hidden;

            img {
                @apply w-full h-full object-cover object-center;
            }
        }

        .media--transaction & {

            .content {

                &-image {
                    @apply mx-auto w-[75px] lg:w-[100px] overflow-hidden;

                    img {
                        @apply w-full h-full object-cover object-center;
                    }
                }

                &-title {

                    h6 {
                        @apply mb-3 font-body lg:text-rg;
                    }
                }
    
                &-pricing {
                    @apply lg:text-rg text-graySemi;
                }
            }
        }

        .media--payment & {

            img {
                @apply max-h-[50px];
            }
        }

        .media--login & {
            @apply w-18 h-18 rounded-full overflow-hidden;

            > img {
                @apply w-full h-full object-cover object-center;
            }
        }
    }

    &-body {

        .media--news & {

            h4 {
                @apply font-body font-bold my-1 line-clamp-1 lg:h-[30px];
            }

            .excerpt {
                @apply hidden lg:block;

                p {
                    @apply line-clamp-3 lg:h-[63px];
                }
            }
        }

        .media--catalog & {

            @include media-breakpoint-down(lg) {
                @apply pt-0;
            }

            > h3 {
                @apply text-3xl lg:text-7xl;

                @include media-breakpoint-down(lg) {
                    @apply mb-3;
                }
            }

            .content {

                p {
                    @apply lg:text-[18px] line-clamp-3 lg:h-[81px];
                }
            }
        }

        .media--catalog-default & {
            @apply pb-12 lg:pb-0 lg:pl-8;

            > h3 {
                @apply mb-4;
            }
        }

        .media--catalog-big & {
            @apply lg:flex pb-12 lg:pb-[124px] lg:pl-16;

            .content {
                @apply lg:pl-20;
            }
        }

        .media--jumbotron & {

            > h2 {
                @apply mb-3 lg:mb-6 text-3xl lg:text-7xl;
            }

            .content {

                p {
                    @apply lg:text-[18px] line-clamp-2 lg:h-[54px];
                }

                &-tag {

                    span {
                        background-color: rgba(26, 26, 26, .3);
                    }
                }
            }
        }

        .media--cart &, .media--checkout & {

            > h6 {
                @apply mb-2 font-body;
            }

            .content {

                &-pricing {

                    > p {
                        @apply font-body font-bold;
                    }
                }
            }
        }

        .media--cart & {
            @apply py-4 lg:py-6;

            > h6 {
                @apply font-extra lg:text-rg;
            }

            .content {

                &-pricing {
                    @apply mb-4;

                    > p {
                        @apply lg:text-xl;
                    }

                    .discount {
                        @apply mt-2;
                    }
                }

                &-option {

                    &__flex {
                        @apply relative flex items-center font-bold lg:w-[150px];

                        > .label {
                            @apply relative w-19;
                            
                            &::after {
                                content: ':';
                                @apply relative inline-block ml-auto;
                            }

                            ~ div {

                                @include media-breakpoint-down(lg) {
                                    @apply w-20;
                                }
                            }
                        }
                    }
                }
            }
        }

        .media--checkout & {

            > h6 {
                @apply text-sm;
            }

            .content {

                &-pricing {

                    > p {
                        @apply lg:text-rg;
                    }

                    .discount {
                        @apply mt-1;
                    }
                }
            }
        }

        .media--transaction & {

            .content {

                &-summary {

                    @include media-breakpoint-down(lg) {
                        @apply flex items-end justify-between mt-2;
                    }

                    .total {

                        p {
                            @apply font-bold text-black;
                        }
                    }
                }
            }
        }

        .media--payment & {

            h6 {
                @apply font-normal mb-2;
            }

            p {
                @apply text-xs;
            }
        }

        .media--delivery & {
            @apply sm:block flex items-center justify-between;

            h6 {
                @apply font-extra mb-0;
            }

            p {
                @apply font-normal text-xs;
            }
        }

        .media--login & {

            h6 {
                @apply font-body font-bold mt-2;
            }
        }
    }

    &-absolute {

        .media--jumbotron & {
            @apply hidden;
        }
        
        img {
            @apply absolute;

            .media--catalog-melinjo & {

                &:nth-child(1) {
                    @apply -bottom-[25px] lg:-bottom-10 -left-[100px] lg:-left-[140px] h-[150px] lg:h-[225px];
                }
            }

            .media--catalog-spicy & {

                &:nth-child(1) {
                    @apply -bottom-5 -right-[90px] lg:-right-[130px] h-[175px] lg:h-[250px];
                }
            }

            .media--jumbotron-cassava & {

                &:nth-child(1) {
                    @apply lg:-bottom-[30px] lg:-left-[175px] lg:h-[200px];
                }

                &:nth-child(2) {
                    @apply lg:-bottom-[105px] lg:-right-[210px] lg:h-[250px];
                }
            }
        }
    }
}