.about {

    &-video {

        &__play {
            @apply rounded-lg overflow-hidden;

            .play {
                @apply absolute top-1/2 left-1/2;
                transform: translate(-50%, -50%);

                &:hover {

                    .play-icon {
                        @apply bg-yellow;

                        svg {
                            @apply fill-black;
                        }
                    }
                }

                &-icon {
                    @apply bg-red mb-2;

                    svg {
                        @apply fill-white;
                    }
                }
            }
        }

        &__overview {

            > h3 {
                @apply lg:mb-6 lg:text-3xl;
            }
        }
    }

    &-vision {

        .media {

            &-image {

                img {
                    @apply w-[75px] lg:w-[125px];
                }
            }

            &-body {
                @apply pl-4 lg:pl-8;

                > h3 {
                    @apply lg:mb-6 lg:text-3xl;
                }
            }
        }
    }
}