.post {

    &-title {

        > h2 {
            @apply mt-2 font-body font-extra lg:text-4xl;
        }
    }

    &-detail {
        @apply prose-headings:mb-3 prose-headings:font-body prose-headings:font-bold prose-p:mb-6 prose-a:text-green prose-a:font-bold prose-ul:mb-6 prose-ol:mb-4 prose-ul:pl-4 prose-ol:pl-4 prose-li:mb-2 prose-ul:list-disc prose-ol:list-decimal prose-img:w-full prose-img:mb-6 prose-img:rounded-lg;

        &.with-height {
            @apply h-[147px] md:h-[63px] lg:h-[63px];
            transition: height .3s;
            overflow: hidden;
        }
        
        ul, ol {

            &:last-child {
                margin-bottom: 0 #{!important};
            }
        }

        p {

            &:last-child {
                margin-bottom: 0 #{!important};
            }
        }

        iframe {
            @apply mx-auto lg:w-4/5 aspect-square lg:aspect-video mb-6 rounded-lg overflow-hidden;
        }

        &__point {
            @apply relative text-left;

            &::before {
                content: '';
                @apply bg-red absolute top-0 left-1/2 h-full;
                width: 1px;
                transform: translateX(-50%);

                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }

            ul {
                @apply mb-0 pl-0 list-none;

                li {
                    @apply relative pl-6;

                    &::before {
                        @apply absolute inline-block top-1 left-0 bg-no-repeat bg-contain w-4 h-4;
                        content: '';
                        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAABHNCSVQICAgIfAhkiAAAAVBJREFUKFONUcFRwzAQvJskM87PHeBPMvIrpgJMBSQVECoAKiB0QAeECggV4A4SXvEkH5cAv8xkMmJ1koVtDGO9pNXtarXL1HHp0Sihfv8d4xlvt7MqTSuVEfOEtD7nLno6ikIKgjVIEUhfnOdhydNx/IT9rZy1vuwmaB1cCOl0mvFutxK+UnPgz07sBQ/NWV4fDCLe7zdtbkFagPTgSI8gLUTsJ4IQzj7ocEi5KD4ZlgvcnwG8wfCyls14PKVe79Vhb8huKmKNCAAl4BodYjjYSKA2Ay8KPAK+BlpzIGNxbL585XPL86w0Yr8cBLYlJwr7KxoOTaOJKQG5pXQ8igPM3vkIiO7h2pTil5TSImoeSGXKlSCutJ5I0/ZhKaEqZva+5V+iliQl1Nq0uC/hT8EWp74EuVNqCXfXEkGlhH8FK6Kmtaw5DFETQ1E22rw35290FrIK6V8CtQAAAABJRU5ErkJggg==");
                    }
                }
            }
        }
    }

    &-share {
        @apply pb-6 lg:pb-12 border-b border-b-[#CCCCCC];

        &__list {

            svg {
                @apply fill-[#666666] h-6;
            }
        }
    }

    &-related {

        &__title {
            
            > h2 {
                @apply lg:text-3xl;
            }
        }
    }
}