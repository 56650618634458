.footer {

    @include media-breakpoint-down(lg) {
        @apply pb-16 #{!important};
    }

    &-menu {

        .row {

            @include media-breakpoint-down(md) {
                @apply text-center;
            }
        }

        &__logo {

            @include media-breakpoint-down(lg) {
                
                img {
                    @apply h-[200px];
                }
            }
        }

        &__address {
        
            h5 {
                @apply mb-3;
            }
        }
    
        &__list {
            @apply pt-1;
            
            @include media-breakpoint-up(lg) {
                padding-top: 25px + 12px;
            }
        }
    }

    &-social {

        &__list {

            svg {
                @apply fill-black h-4;
            }
        }
    }
}