$slick-loader-path: "/assets/js/vendor/slick/" !default;

@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

.slick {
    @apply opacity-0;

    &.slick-initialized {
        @apply opacity-100;
    }

    // Change animation presets
    [data-animation-in] {
        opacity: 0;
        animation-duration: 2s;
        transition: opacity 0.25s ease 0.3s;
        transition: 1s;
    }

    &--product {

        &.slick-slider {
            display: table!important;
            table-layout: fixed!important;
            width: 100%!important;
        }
    }

    &-list {

        .slick--gallery & {
            overflow: unset;
        }
    }

    &-slide {
        
        .slick--banner & {

            .item {
                @apply relative overflow-hidden;
                transition: 1s;

                @include media-breakpoint-down(lg) {
                    padding-top: 132px;
                }

                @include media-breakpoint-up(lg) {
                    padding-top: 56px+80px;
                    aspect-ratio: 2/1.15;
                }
        
                &-dialog {
                    @apply relative z-[1];
                    
                    @include media-breakpoint-down(lg) {
                        height: 625px;
                    }
                    
                    .animated {
                        transition: all .5s ease;
                    }
                }
        
                &-title, &-content {
                    @apply mt-8 lg:mt-19;
                }
        
                &-title {
                    
                    p {
                        @apply lg:mb-4 font-bold;
                        letter-spacing: 5px;
                    }
        
                    h2 {
                        @apply text-2xl lg:text-7xl;
                    }
                }
        
                &-content {
        
                    @include media-breakpoint-down(lg) {
                        @apply mt-0 w-full;
                    }
                }
        
                &-image {
                    @apply w-full lg:w-1/2 overflow-hidden;
        
                    img {
                        @apply -rotate-12 mx-auto lg:h-[calc(100vh-15vh)] 3xl:h-auto object-center object-cover;
        
                        @include media-breakpoint-down(lg) {
                            height: 350px;
                        }
                    }
                }

                &-single {
                    @apply overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-center prose-img:object-cover;
                    
                    @include media-breakpoint-down(lg) {
                        height: 625px;
                    }

                    @include media-breakpoint-up(lg) {
                        aspect-ratio: inherit;
                    }
                }
        
                &-absolute {
        
                    @include media-breakpoint-down(md) {
                        @apply hidden;
                    }

                    img {

                        &:nth-child(1) {
                            @apply absolute -bottom-8;
            
                            @include media-breakpoint-up(lg) {
                                left: -175px;
                                width: 350px;
                            }
            
                            @include media-breakpoint-only(md) {
                                @apply hidden;
                            }
                        }

                        &:nth-child(2) {
                            @apply absolute -right-12 lg:w-[200px];
                            top: calc(80px + 20px);
                        }

                        &:nth-child(3) {
                            @apply absolute lg:bottom-16 lg:right-16;
            
                            @include media-breakpoint-up(lg) {
                                width: 325px;
                            }
            
                            @include media-breakpoint-only(md) {
                                @apply hidden;
                            }
                        }
                    }
        
                    // .image-5 {
                    //     @apply absolute -bottom-8;
        
                    //     @include media-breakpoint-up(lg) {
                    //         left: -75px;
                    //         width: 325px;
                    //     }
        
                    //     @include media-breakpoint-only(md) {
                    //         @apply hidden;
                    //     }
                    // }
        
                    // .image-6 {
                    //     @apply absolute -right-19 lg:w-[250px];
                    //     top: calc(80px + 10px);
                    // }
        
                    // .image-7 {
                    //     @apply absolute -bottom-4;
        
                    //     @include media-breakpoint-up(lg) {
                    //         left: -165px;
                    //         width: 450px;
                    //     }
        
                    //     @include media-breakpoint-only(md) {
                    //         @apply hidden;
                    //     }
                    // }
                }
            }
        }

        .slick--product-main & {

            .item {
                @apply mx-auto sm:aspect-[3/4] md:h-[400px] lg:h-[400px];

                img {
                    @apply w-full h-full object-contain object-center;
                }
            }
        }

        .slick--product-thumbnail & {
            @apply m-3;

            &.slick-current {

                .item {
                    @apply bg-white border-red;
                    box-shadow: 0 4px 16px rgba($color: #000000, $alpha: .1);
                }
            }

            .item {
                @apply bg-transparent px-2 border border-transparent rounded-rg overflow-hidden;
            }
        }

        .slick--gallery & {
            @apply mx-1 lg:mx-4;

            .item {
                
                &-actions {
                    @apply block aspect-[3/2] rounded-lg overflow-hidden;

                    > img {
                        @apply w-full h-full object-cover object-center;
                    }
                }
            }
        }

        .slick--store & {
            @apply mx-4;
        }
    }

    &-prev, &-next {
        @apply overflow-hidden z-30 before:hidden;

        &.slick-disabled {
            @apply opacity-50;
        }
        
        .slick--banner & {
            @apply bg-red w-8 h-8 lg:w-12 lg:h-12 rounded-full;
            top: calc(50% - 40px);

            &:hover {
                @apply bg-yellow;
            }
        }

        .slick--gallery & {
            @apply bg-transparent top-[unset] -bottom-14 lg:-bottom-[76px] w-9 h-9 rounded-full transition ease-in-out;
            
            &:hover {
                @apply bg-yellow;

                svg {
                    @apply fill-black;
                }
            }
        }
    }

    &-prev {
        
        .slick--banner & {
            @apply left-3 lg:left-5;
        }

        .slick--product &, .slick--store & {
            @apply -left-2 lg:-left-4;
        }

        .slick--gallery & {
            @apply left-[28vw] lg:left-[42.5%];
        }
    }

    &-next {
        
        .slick--banner & {
            @apply right-3 lg:right-5;
        }

        .slick--product &, .slick--store & {
            @apply -right-2 lg:-right-4;
        }

        .slick--gallery & {
            @apply right-[28vw] lg:right-[42.5%];
        }
    }

    &-dotted {

        &.slick-slider {
            @apply mb-0;
        }

        &.slick--gallery, &.slick--store {
            @apply mb-6 lg:mb-12;
        }
    }

    &-dots {

        > li {
            @apply mx-1 w-2 h-2;

            &.slick-active {

                button {
                    @apply bg-red #{!important};
                }
            }

            button {
                @apply bg-white w-full h-full p-0 rounded-full overflow-hidden before:hidden;
            }
        }

        .slick--banner & {
            @apply bottom-8;

            > li {
                @apply lg:w-4 lg:h-4;
            }
        }

        .slick--product &, .slick--gallery &, .slick--store & {

            > li {
                @apply lg:w-3 lg:h-3;

                &.slick-active {
                    @apply w-3 h-3 lg:w-4 lg:h-4;
                }
            }
        }

        .slick--product &, .slick--store & {

            > li {

                button {
                    @apply bg-gray;
                }
            }
        }

        .slick--gallery & {
            @apply relative lg:-bottom-12;

            > li {

                button {
                    @apply bg-[#C5C5C5];
                }
            }
        }

        .slick--store & {
            @apply lg:-bottom-12;
        }
    }
}