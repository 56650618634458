.accordion {

    &-item {

        .accordion--faq & {
            @apply bg-white rounded-lg overflow-hidden;

            + .accordion-item {
                @apply mt-5;
            }
        }

        .accordion--payment & {

            + .accordion-item {
                @apply mt-4;
            }
        }
    }

    &-toggle {

        h6 {
            @apply flex-1 pr-4 font-body font-extra;

            .accordion--faq & {
                @apply lg:text-lg;
            }

            .accordion--payment & {
                @apply mb-0 text-red;
            }
        }

        &[aria-expanded=true] {
            background-color: inherit;
            transition-duration: .75s;
            transition-property: background-color, border-color;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

            .icon {
                background-image: url('../images/icon-minus-solid.svg');
            }
        }

        &[aria-expanded=false] {
            transition-duration: .75s;
            transition-property: background-color, border-color;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .icon {
            @apply inline-block w-3 h-3 bg-contain bg-center bg-no-repeat transition ease-in-out;
            background-image: url('../images/icon-plus-solid.svg');
        }
    }

    &-content {
        transition-duration: .5s;
        transition-property: transform, opacity;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &[aria-hidden=true] {
            height: 0;
            opacity: 0;
            transform: translate3d(0, -0.5rem, 0);
            visibility: hidden;
        }

        &[aria-hidden=false] {
            height: auto;
            opacity: 1;
            transform: translate3d(0, 0, 0);
            visibility: visible;
        }
    }
}