@charset "UTF-8";
@import "~animate.css/animate.css";
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 24px;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
body[data-aos-duration="50"] [data-aos], [data-aos][data-aos][data-aos-duration="50"] {
  transition-duration: 50ms;
}
body[data-aos-delay="50"] [data-aos], [data-aos][data-aos][data-aos-delay="50"] {
  transition-delay: 0s;
}
body[data-aos-delay="50"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="50"].aos-animate {
  transition-delay: 50ms;
}
body[data-aos-duration="100"] [data-aos], [data-aos][data-aos][data-aos-duration="100"] {
  transition-duration: 100ms;
}
body[data-aos-delay="100"] [data-aos], [data-aos][data-aos][data-aos-delay="100"] {
  transition-delay: 0s;
}
body[data-aos-delay="100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="100"].aos-animate {
  transition-delay: 100ms;
}
body[data-aos-duration="150"] [data-aos], [data-aos][data-aos][data-aos-duration="150"] {
  transition-duration: 150ms;
}
body[data-aos-delay="150"] [data-aos], [data-aos][data-aos][data-aos-delay="150"] {
  transition-delay: 0s;
}
body[data-aos-delay="150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="150"].aos-animate {
  transition-delay: 150ms;
}
body[data-aos-duration="200"] [data-aos], [data-aos][data-aos][data-aos-duration="200"] {
  transition-duration: 200ms;
}
body[data-aos-delay="200"] [data-aos], [data-aos][data-aos][data-aos-delay="200"] {
  transition-delay: 0s;
}
body[data-aos-delay="200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="200"].aos-animate {
  transition-delay: 200ms;
}
body[data-aos-duration="250"] [data-aos], [data-aos][data-aos][data-aos-duration="250"] {
  transition-duration: 250ms;
}
body[data-aos-delay="250"] [data-aos], [data-aos][data-aos][data-aos-delay="250"] {
  transition-delay: 0s;
}
body[data-aos-delay="250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="250"].aos-animate {
  transition-delay: 250ms;
}
body[data-aos-duration="300"] [data-aos], [data-aos][data-aos][data-aos-duration="300"] {
  transition-duration: 300ms;
}
body[data-aos-delay="300"] [data-aos], [data-aos][data-aos][data-aos-delay="300"] {
  transition-delay: 0s;
}
body[data-aos-delay="300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="300"].aos-animate {
  transition-delay: 300ms;
}
body[data-aos-duration="350"] [data-aos], [data-aos][data-aos][data-aos-duration="350"] {
  transition-duration: 350ms;
}
body[data-aos-delay="350"] [data-aos], [data-aos][data-aos][data-aos-delay="350"] {
  transition-delay: 0s;
}
body[data-aos-delay="350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="350"].aos-animate {
  transition-delay: 350ms;
}
body[data-aos-duration="400"] [data-aos], [data-aos][data-aos][data-aos-duration="400"] {
  transition-duration: 400ms;
}
body[data-aos-delay="400"] [data-aos], [data-aos][data-aos][data-aos-delay="400"] {
  transition-delay: 0s;
}
body[data-aos-delay="400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="400"].aos-animate {
  transition-delay: 400ms;
}
body[data-aos-duration="450"] [data-aos], [data-aos][data-aos][data-aos-duration="450"] {
  transition-duration: 450ms;
}
body[data-aos-delay="450"] [data-aos], [data-aos][data-aos][data-aos-delay="450"] {
  transition-delay: 0s;
}
body[data-aos-delay="450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="450"].aos-animate {
  transition-delay: 450ms;
}
body[data-aos-duration="500"] [data-aos], [data-aos][data-aos][data-aos-duration="500"] {
  transition-duration: 500ms;
}
body[data-aos-delay="500"] [data-aos], [data-aos][data-aos][data-aos-delay="500"] {
  transition-delay: 0s;
}
body[data-aos-delay="500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="500"].aos-animate {
  transition-delay: 500ms;
}
body[data-aos-duration="550"] [data-aos], [data-aos][data-aos][data-aos-duration="550"] {
  transition-duration: 550ms;
}
body[data-aos-delay="550"] [data-aos], [data-aos][data-aos][data-aos-delay="550"] {
  transition-delay: 0s;
}
body[data-aos-delay="550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="550"].aos-animate {
  transition-delay: 550ms;
}
body[data-aos-duration="600"] [data-aos], [data-aos][data-aos][data-aos-duration="600"] {
  transition-duration: 600ms;
}
body[data-aos-delay="600"] [data-aos], [data-aos][data-aos][data-aos-delay="600"] {
  transition-delay: 0s;
}
body[data-aos-delay="600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="600"].aos-animate {
  transition-delay: 600ms;
}
body[data-aos-duration="650"] [data-aos], [data-aos][data-aos][data-aos-duration="650"] {
  transition-duration: 650ms;
}
body[data-aos-delay="650"] [data-aos], [data-aos][data-aos][data-aos-delay="650"] {
  transition-delay: 0s;
}
body[data-aos-delay="650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="650"].aos-animate {
  transition-delay: 650ms;
}
body[data-aos-duration="700"] [data-aos], [data-aos][data-aos][data-aos-duration="700"] {
  transition-duration: 700ms;
}
body[data-aos-delay="700"] [data-aos], [data-aos][data-aos][data-aos-delay="700"] {
  transition-delay: 0s;
}
body[data-aos-delay="700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="700"].aos-animate {
  transition-delay: 700ms;
}
body[data-aos-duration="750"] [data-aos], [data-aos][data-aos][data-aos-duration="750"] {
  transition-duration: 750ms;
}
body[data-aos-delay="750"] [data-aos], [data-aos][data-aos][data-aos-delay="750"] {
  transition-delay: 0s;
}
body[data-aos-delay="750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="750"].aos-animate {
  transition-delay: 750ms;
}
body[data-aos-duration="800"] [data-aos], [data-aos][data-aos][data-aos-duration="800"] {
  transition-duration: 800ms;
}
body[data-aos-delay="800"] [data-aos], [data-aos][data-aos][data-aos-delay="800"] {
  transition-delay: 0s;
}
body[data-aos-delay="800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="800"].aos-animate {
  transition-delay: 800ms;
}
body[data-aos-duration="850"] [data-aos], [data-aos][data-aos][data-aos-duration="850"] {
  transition-duration: 850ms;
}
body[data-aos-delay="850"] [data-aos], [data-aos][data-aos][data-aos-delay="850"] {
  transition-delay: 0s;
}
body[data-aos-delay="850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="850"].aos-animate {
  transition-delay: 850ms;
}
body[data-aos-duration="900"] [data-aos], [data-aos][data-aos][data-aos-duration="900"] {
  transition-duration: 900ms;
}
body[data-aos-delay="900"] [data-aos], [data-aos][data-aos][data-aos-delay="900"] {
  transition-delay: 0s;
}
body[data-aos-delay="900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="900"].aos-animate {
  transition-delay: 900ms;
}
body[data-aos-duration="950"] [data-aos], [data-aos][data-aos][data-aos-duration="950"] {
  transition-duration: 950ms;
}
body[data-aos-delay="950"] [data-aos], [data-aos][data-aos][data-aos-delay="950"] {
  transition-delay: 0s;
}
body[data-aos-delay="950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="950"].aos-animate {
  transition-delay: 950ms;
}
body[data-aos-duration="1000"] [data-aos], [data-aos][data-aos][data-aos-duration="1000"] {
  transition-duration: 1000ms;
}
body[data-aos-delay="1000"] [data-aos], [data-aos][data-aos][data-aos-delay="1000"] {
  transition-delay: 0s;
}
body[data-aos-delay="1000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1000"].aos-animate {
  transition-delay: 1000ms;
}
body[data-aos-duration="1050"] [data-aos], [data-aos][data-aos][data-aos-duration="1050"] {
  transition-duration: 1050ms;
}
body[data-aos-delay="1050"] [data-aos], [data-aos][data-aos][data-aos-delay="1050"] {
  transition-delay: 0s;
}
body[data-aos-delay="1050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1050"].aos-animate {
  transition-delay: 1050ms;
}
body[data-aos-duration="1100"] [data-aos], [data-aos][data-aos][data-aos-duration="1100"] {
  transition-duration: 1100ms;
}
body[data-aos-delay="1100"] [data-aos], [data-aos][data-aos][data-aos-delay="1100"] {
  transition-delay: 0s;
}
body[data-aos-delay="1100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1100"].aos-animate {
  transition-delay: 1100ms;
}
body[data-aos-duration="1150"] [data-aos], [data-aos][data-aos][data-aos-duration="1150"] {
  transition-duration: 1150ms;
}
body[data-aos-delay="1150"] [data-aos], [data-aos][data-aos][data-aos-delay="1150"] {
  transition-delay: 0s;
}
body[data-aos-delay="1150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1150"].aos-animate {
  transition-delay: 1150ms;
}
body[data-aos-duration="1200"] [data-aos], [data-aos][data-aos][data-aos-duration="1200"] {
  transition-duration: 1200ms;
}
body[data-aos-delay="1200"] [data-aos], [data-aos][data-aos][data-aos-delay="1200"] {
  transition-delay: 0s;
}
body[data-aos-delay="1200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1200"].aos-animate {
  transition-delay: 1200ms;
}
body[data-aos-duration="1250"] [data-aos], [data-aos][data-aos][data-aos-duration="1250"] {
  transition-duration: 1250ms;
}
body[data-aos-delay="1250"] [data-aos], [data-aos][data-aos][data-aos-delay="1250"] {
  transition-delay: 0s;
}
body[data-aos-delay="1250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1250"].aos-animate {
  transition-delay: 1250ms;
}
body[data-aos-duration="1300"] [data-aos], [data-aos][data-aos][data-aos-duration="1300"] {
  transition-duration: 1300ms;
}
body[data-aos-delay="1300"] [data-aos], [data-aos][data-aos][data-aos-delay="1300"] {
  transition-delay: 0s;
}
body[data-aos-delay="1300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1300"].aos-animate {
  transition-delay: 1300ms;
}
body[data-aos-duration="1350"] [data-aos], [data-aos][data-aos][data-aos-duration="1350"] {
  transition-duration: 1350ms;
}
body[data-aos-delay="1350"] [data-aos], [data-aos][data-aos][data-aos-delay="1350"] {
  transition-delay: 0s;
}
body[data-aos-delay="1350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1350"].aos-animate {
  transition-delay: 1350ms;
}
body[data-aos-duration="1400"] [data-aos], [data-aos][data-aos][data-aos-duration="1400"] {
  transition-duration: 1400ms;
}
body[data-aos-delay="1400"] [data-aos], [data-aos][data-aos][data-aos-delay="1400"] {
  transition-delay: 0s;
}
body[data-aos-delay="1400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1400"].aos-animate {
  transition-delay: 1400ms;
}
body[data-aos-duration="1450"] [data-aos], [data-aos][data-aos][data-aos-duration="1450"] {
  transition-duration: 1450ms;
}
body[data-aos-delay="1450"] [data-aos], [data-aos][data-aos][data-aos-delay="1450"] {
  transition-delay: 0s;
}
body[data-aos-delay="1450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1450"].aos-animate {
  transition-delay: 1450ms;
}
body[data-aos-duration="1500"] [data-aos], [data-aos][data-aos][data-aos-duration="1500"] {
  transition-duration: 1500ms;
}
body[data-aos-delay="1500"] [data-aos], [data-aos][data-aos][data-aos-delay="1500"] {
  transition-delay: 0s;
}
body[data-aos-delay="1500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1500"].aos-animate {
  transition-delay: 1500ms;
}
body[data-aos-duration="1550"] [data-aos], [data-aos][data-aos][data-aos-duration="1550"] {
  transition-duration: 1550ms;
}
body[data-aos-delay="1550"] [data-aos], [data-aos][data-aos][data-aos-delay="1550"] {
  transition-delay: 0s;
}
body[data-aos-delay="1550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1550"].aos-animate {
  transition-delay: 1550ms;
}
body[data-aos-duration="1600"] [data-aos], [data-aos][data-aos][data-aos-duration="1600"] {
  transition-duration: 1600ms;
}
body[data-aos-delay="1600"] [data-aos], [data-aos][data-aos][data-aos-delay="1600"] {
  transition-delay: 0s;
}
body[data-aos-delay="1600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1600"].aos-animate {
  transition-delay: 1600ms;
}
body[data-aos-duration="1650"] [data-aos], [data-aos][data-aos][data-aos-duration="1650"] {
  transition-duration: 1650ms;
}
body[data-aos-delay="1650"] [data-aos], [data-aos][data-aos][data-aos-delay="1650"] {
  transition-delay: 0s;
}
body[data-aos-delay="1650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1650"].aos-animate {
  transition-delay: 1650ms;
}
body[data-aos-duration="1700"] [data-aos], [data-aos][data-aos][data-aos-duration="1700"] {
  transition-duration: 1700ms;
}
body[data-aos-delay="1700"] [data-aos], [data-aos][data-aos][data-aos-delay="1700"] {
  transition-delay: 0s;
}
body[data-aos-delay="1700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1700"].aos-animate {
  transition-delay: 1700ms;
}
body[data-aos-duration="1750"] [data-aos], [data-aos][data-aos][data-aos-duration="1750"] {
  transition-duration: 1750ms;
}
body[data-aos-delay="1750"] [data-aos], [data-aos][data-aos][data-aos-delay="1750"] {
  transition-delay: 0s;
}
body[data-aos-delay="1750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1750"].aos-animate {
  transition-delay: 1750ms;
}
body[data-aos-duration="1800"] [data-aos], [data-aos][data-aos][data-aos-duration="1800"] {
  transition-duration: 1800ms;
}
body[data-aos-delay="1800"] [data-aos], [data-aos][data-aos][data-aos-delay="1800"] {
  transition-delay: 0s;
}
body[data-aos-delay="1800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1800"].aos-animate {
  transition-delay: 1800ms;
}
body[data-aos-duration="1850"] [data-aos], [data-aos][data-aos][data-aos-duration="1850"] {
  transition-duration: 1850ms;
}
body[data-aos-delay="1850"] [data-aos], [data-aos][data-aos][data-aos-delay="1850"] {
  transition-delay: 0s;
}
body[data-aos-delay="1850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1850"].aos-animate {
  transition-delay: 1850ms;
}
body[data-aos-duration="1900"] [data-aos], [data-aos][data-aos][data-aos-duration="1900"] {
  transition-duration: 1900ms;
}
body[data-aos-delay="1900"] [data-aos], [data-aos][data-aos][data-aos-delay="1900"] {
  transition-delay: 0s;
}
body[data-aos-delay="1900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1900"].aos-animate {
  transition-delay: 1900ms;
}
body[data-aos-duration="1950"] [data-aos], [data-aos][data-aos][data-aos-duration="1950"] {
  transition-duration: 1950ms;
}
body[data-aos-delay="1950"] [data-aos], [data-aos][data-aos][data-aos-delay="1950"] {
  transition-delay: 0s;
}
body[data-aos-delay="1950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1950"].aos-animate {
  transition-delay: 1950ms;
}
body[data-aos-duration="2000"] [data-aos], [data-aos][data-aos][data-aos-duration="2000"] {
  transition-duration: 2000ms;
}
body[data-aos-delay="2000"] [data-aos], [data-aos][data-aos][data-aos-delay="2000"] {
  transition-delay: 0s;
}
body[data-aos-delay="2000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2000"].aos-animate {
  transition-delay: 2000ms;
}
body[data-aos-duration="2050"] [data-aos], [data-aos][data-aos][data-aos-duration="2050"] {
  transition-duration: 2050ms;
}
body[data-aos-delay="2050"] [data-aos], [data-aos][data-aos][data-aos-delay="2050"] {
  transition-delay: 0s;
}
body[data-aos-delay="2050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2050"].aos-animate {
  transition-delay: 2050ms;
}
body[data-aos-duration="2100"] [data-aos], [data-aos][data-aos][data-aos-duration="2100"] {
  transition-duration: 2100ms;
}
body[data-aos-delay="2100"] [data-aos], [data-aos][data-aos][data-aos-delay="2100"] {
  transition-delay: 0s;
}
body[data-aos-delay="2100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2100"].aos-animate {
  transition-delay: 2100ms;
}
body[data-aos-duration="2150"] [data-aos], [data-aos][data-aos][data-aos-duration="2150"] {
  transition-duration: 2150ms;
}
body[data-aos-delay="2150"] [data-aos], [data-aos][data-aos][data-aos-delay="2150"] {
  transition-delay: 0s;
}
body[data-aos-delay="2150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2150"].aos-animate {
  transition-delay: 2150ms;
}
body[data-aos-duration="2200"] [data-aos], [data-aos][data-aos][data-aos-duration="2200"] {
  transition-duration: 2200ms;
}
body[data-aos-delay="2200"] [data-aos], [data-aos][data-aos][data-aos-delay="2200"] {
  transition-delay: 0s;
}
body[data-aos-delay="2200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2200"].aos-animate {
  transition-delay: 2200ms;
}
body[data-aos-duration="2250"] [data-aos], [data-aos][data-aos][data-aos-duration="2250"] {
  transition-duration: 2250ms;
}
body[data-aos-delay="2250"] [data-aos], [data-aos][data-aos][data-aos-delay="2250"] {
  transition-delay: 0s;
}
body[data-aos-delay="2250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2250"].aos-animate {
  transition-delay: 2250ms;
}
body[data-aos-duration="2300"] [data-aos], [data-aos][data-aos][data-aos-duration="2300"] {
  transition-duration: 2300ms;
}
body[data-aos-delay="2300"] [data-aos], [data-aos][data-aos][data-aos-delay="2300"] {
  transition-delay: 0s;
}
body[data-aos-delay="2300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2300"].aos-animate {
  transition-delay: 2300ms;
}
body[data-aos-duration="2350"] [data-aos], [data-aos][data-aos][data-aos-duration="2350"] {
  transition-duration: 2350ms;
}
body[data-aos-delay="2350"] [data-aos], [data-aos][data-aos][data-aos-delay="2350"] {
  transition-delay: 0s;
}
body[data-aos-delay="2350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2350"].aos-animate {
  transition-delay: 2350ms;
}
body[data-aos-duration="2400"] [data-aos], [data-aos][data-aos][data-aos-duration="2400"] {
  transition-duration: 2400ms;
}
body[data-aos-delay="2400"] [data-aos], [data-aos][data-aos][data-aos-delay="2400"] {
  transition-delay: 0s;
}
body[data-aos-delay="2400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2400"].aos-animate {
  transition-delay: 2400ms;
}
body[data-aos-duration="2450"] [data-aos], [data-aos][data-aos][data-aos-duration="2450"] {
  transition-duration: 2450ms;
}
body[data-aos-delay="2450"] [data-aos], [data-aos][data-aos][data-aos-delay="2450"] {
  transition-delay: 0s;
}
body[data-aos-delay="2450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2450"].aos-animate {
  transition-delay: 2450ms;
}
body[data-aos-duration="2500"] [data-aos], [data-aos][data-aos][data-aos-duration="2500"] {
  transition-duration: 2500ms;
}
body[data-aos-delay="2500"] [data-aos], [data-aos][data-aos][data-aos-delay="2500"] {
  transition-delay: 0s;
}
body[data-aos-delay="2500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2500"].aos-animate {
  transition-delay: 2500ms;
}
body[data-aos-duration="2550"] [data-aos], [data-aos][data-aos][data-aos-duration="2550"] {
  transition-duration: 2550ms;
}
body[data-aos-delay="2550"] [data-aos], [data-aos][data-aos][data-aos-delay="2550"] {
  transition-delay: 0s;
}
body[data-aos-delay="2550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2550"].aos-animate {
  transition-delay: 2550ms;
}
body[data-aos-duration="2600"] [data-aos], [data-aos][data-aos][data-aos-duration="2600"] {
  transition-duration: 2600ms;
}
body[data-aos-delay="2600"] [data-aos], [data-aos][data-aos][data-aos-delay="2600"] {
  transition-delay: 0s;
}
body[data-aos-delay="2600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2600"].aos-animate {
  transition-delay: 2600ms;
}
body[data-aos-duration="2650"] [data-aos], [data-aos][data-aos][data-aos-duration="2650"] {
  transition-duration: 2650ms;
}
body[data-aos-delay="2650"] [data-aos], [data-aos][data-aos][data-aos-delay="2650"] {
  transition-delay: 0s;
}
body[data-aos-delay="2650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2650"].aos-animate {
  transition-delay: 2650ms;
}
body[data-aos-duration="2700"] [data-aos], [data-aos][data-aos][data-aos-duration="2700"] {
  transition-duration: 2700ms;
}
body[data-aos-delay="2700"] [data-aos], [data-aos][data-aos][data-aos-delay="2700"] {
  transition-delay: 0s;
}
body[data-aos-delay="2700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2700"].aos-animate {
  transition-delay: 2700ms;
}
body[data-aos-duration="2750"] [data-aos], [data-aos][data-aos][data-aos-duration="2750"] {
  transition-duration: 2750ms;
}
body[data-aos-delay="2750"] [data-aos], [data-aos][data-aos][data-aos-delay="2750"] {
  transition-delay: 0s;
}
body[data-aos-delay="2750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2750"].aos-animate {
  transition-delay: 2750ms;
}
body[data-aos-duration="2800"] [data-aos], [data-aos][data-aos][data-aos-duration="2800"] {
  transition-duration: 2800ms;
}
body[data-aos-delay="2800"] [data-aos], [data-aos][data-aos][data-aos-delay="2800"] {
  transition-delay: 0s;
}
body[data-aos-delay="2800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2800"].aos-animate {
  transition-delay: 2800ms;
}
body[data-aos-duration="2850"] [data-aos], [data-aos][data-aos][data-aos-duration="2850"] {
  transition-duration: 2850ms;
}
body[data-aos-delay="2850"] [data-aos], [data-aos][data-aos][data-aos-delay="2850"] {
  transition-delay: 0s;
}
body[data-aos-delay="2850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2850"].aos-animate {
  transition-delay: 2850ms;
}
body[data-aos-duration="2900"] [data-aos], [data-aos][data-aos][data-aos-duration="2900"] {
  transition-duration: 2900ms;
}
body[data-aos-delay="2900"] [data-aos], [data-aos][data-aos][data-aos-delay="2900"] {
  transition-delay: 0s;
}
body[data-aos-delay="2900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2900"].aos-animate {
  transition-delay: 2900ms;
}
body[data-aos-duration="2950"] [data-aos], [data-aos][data-aos][data-aos-duration="2950"] {
  transition-duration: 2950ms;
}
body[data-aos-delay="2950"] [data-aos], [data-aos][data-aos][data-aos-delay="2950"] {
  transition-delay: 0s;
}
body[data-aos-delay="2950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2950"].aos-animate {
  transition-delay: 2950ms;
}
body[data-aos-duration="3000"] [data-aos], [data-aos][data-aos][data-aos-duration="3000"] {
  transition-duration: 3000ms;
}
body[data-aos-delay="3000"] [data-aos], [data-aos][data-aos][data-aos-delay="3000"] {
  transition-delay: 0s;
}
body[data-aos-delay="3000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="3000"].aos-animate {
  transition-delay: 3000ms;
}

[data-aos] {
  pointer-events: none;
}
[data-aos].aos-animate {
  pointer-events: auto;
}

body[data-aos-easing=linear] [data-aos], [data-aos][data-aos][data-aos-easing=linear] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}
body[data-aos-easing=ease] [data-aos], [data-aos][data-aos][data-aos-easing=ease] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}
body[data-aos-easing=ease-in] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
}
body[data-aos-easing=ease-out] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}
body[data-aos-easing=ease-in-out] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
body[data-aos-easing=ease-in-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-back] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
body[data-aos-easing=ease-out-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-back] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
body[data-aos-easing=ease-in-out-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-back] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
body[data-aos-easing=ease-in-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-sine] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}
body[data-aos-easing=ease-out-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-sine] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
body[data-aos-easing=ease-in-out-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-sine] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
body[data-aos-easing=ease-in-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-quad] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-quad] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-quad] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
body[data-aos-easing=ease-in-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-cubic] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-cubic] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-cubic] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
body[data-aos-easing=ease-in-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-quart] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-quart] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-quart] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

@media screen {
  html:not(.no-js) {
    /**
    * Fade animations:
    * fade
    * fade-up, fade-down, fade-left, fade-right
    * fade-up-right, fade-up-left, fade-down-right, fade-down-left
    */
    /**
    * Zoom animations:
    * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
    * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
    */
    /**
    * Slide animations
    */
    /**
    * Flip animations:
    * flip-left, flip-right, flip-up, flip-down
    */
  }
  html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
    opacity: 0;
    transition-property: opacity, transform;
  }
  html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate {
    opacity: 1;
    transform: none;
  }
  html:not(.no-js) [data-aos=fade-up] {
    transform: translate3d(0, 100px, 0);
  }
  html:not(.no-js) [data-aos=fade-down] {
    transform: translate3d(0, -100px, 0);
  }
  html:not(.no-js) [data-aos=fade-right] {
    transform: translate3d(-100px, 0, 0);
  }
  html:not(.no-js) [data-aos=fade-left] {
    transform: translate3d(100px, 0, 0);
  }
  html:not(.no-js) [data-aos=fade-up-right] {
    transform: translate3d(-100px, 100px, 0);
  }
  html:not(.no-js) [data-aos=fade-up-left] {
    transform: translate3d(100px, 100px, 0);
  }
  html:not(.no-js) [data-aos=fade-down-right] {
    transform: translate3d(-100px, -100px, 0);
  }
  html:not(.no-js) [data-aos=fade-down-left] {
    transform: translate3d(100px, -100px, 0);
  }
  html:not(.no-js) [data-aos^=zoom][data-aos^=zoom] {
    opacity: 0;
    transition-property: opacity, transform;
  }
  html:not(.no-js) [data-aos^=zoom][data-aos^=zoom].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
  html:not(.no-js) [data-aos=zoom-in] {
    transform: scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-up] {
    transform: translate3d(0, 100px, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-down] {
    transform: translate3d(0, -100px, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-right] {
    transform: translate3d(-100px, 0, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-left] {
    transform: translate3d(100px, 0, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-out] {
    transform: scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-up] {
    transform: translate3d(0, 100px, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-down] {
    transform: translate3d(0, -100px, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-right] {
    transform: translate3d(-100px, 0, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-left] {
    transform: translate3d(100px, 0, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos^=slide][data-aos^=slide] {
    transition-property: transform;
    visibility: hidden;
  }
  html:not(.no-js) [data-aos^=slide][data-aos^=slide].aos-animate {
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
  html:not(.no-js) [data-aos=slide-up] {
    transform: translate3d(0, 100%, 0);
  }
  html:not(.no-js) [data-aos=slide-down] {
    transform: translate3d(0, -100%, 0);
  }
  html:not(.no-js) [data-aos=slide-right] {
    transform: translate3d(-100%, 0, 0);
  }
  html:not(.no-js) [data-aos=slide-left] {
    transform: translate3d(100%, 0, 0);
  }
  html:not(.no-js) [data-aos^=flip][data-aos^=flip] {
    backface-visibility: hidden;
    transition-property: transform;
  }
  html:not(.no-js) [data-aos=flip-left] {
    transform: perspective(2500px) rotateY(-100deg);
  }
  html:not(.no-js) [data-aos=flip-left].aos-animate {
    transform: perspective(2500px) rotateY(0);
  }
  html:not(.no-js) [data-aos=flip-right] {
    transform: perspective(2500px) rotateY(100deg);
  }
  html:not(.no-js) [data-aos=flip-right].aos-animate {
    transform: perspective(2500px) rotateY(0);
  }
  html:not(.no-js) [data-aos=flip-up] {
    transform: perspective(2500px) rotateX(-100deg);
  }
  html:not(.no-js) [data-aos=flip-up].aos-animate {
    transform: perspective(2500px) rotateX(0);
  }
  html:not(.no-js) [data-aos=flip-down] {
    transform: perspective(2500px) rotateX(100deg);
  }
  html:not(.no-js) [data-aos=flip-down].aos-animate {
    transform: perspective(2500px) rotateX(0);
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/assets/js/vendor/slick/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

.slick {
  @apply opacity-0;
}
.slick.slick-initialized {
  @apply opacity-100;
}
.slick [data-animation-in] {
  opacity: 0;
  animation-duration: 2s;
  transition: opacity 0.25s ease 0.3s;
  transition: 1s;
}
.slick--product.slick-slider {
  display: table !important;
  table-layout: fixed !important;
  width: 100% !important;
}
.slick--gallery .slick-list {
  overflow: unset;
}
.slick--banner .slick-slide .item {
  @apply relative overflow-hidden;
  transition: 1s;
}
@media (max-width: 991.98px) {
  .slick--banner .slick-slide .item {
    padding-top: 132px;
  }
}
@media (min-width: 992px) {
  .slick--banner .slick-slide .item {
    padding-top: 136px;
    aspect-ratio: 2/1.15;
  }
}
.slick--banner .slick-slide .item-dialog {
  @apply relative z-[1];
}
@media (max-width: 991.98px) {
  .slick--banner .slick-slide .item-dialog {
    height: 625px;
  }
}
.slick--banner .slick-slide .item-dialog .animated {
  transition: all 0.5s ease;
}
.slick--banner .slick-slide .item-title, .slick--banner .slick-slide .item-content {
  @apply mt-8 lg:mt-19;
}
.slick--banner .slick-slide .item-title p {
  @apply lg:mb-4 font-bold;
  letter-spacing: 5px;
}
.slick--banner .slick-slide .item-title h2 {
  @apply text-2xl lg:text-7xl;
}
@media (max-width: 991.98px) {
  .slick--banner .slick-slide .item-content {
    @apply mt-0 w-full;
  }
}
.slick--banner .slick-slide .item-image {
  @apply w-full lg:w-1/2 overflow-hidden;
}
.slick--banner .slick-slide .item-image img {
  @apply -rotate-12 mx-auto lg:h-[calc(100vh-15vh)] 3xl:h-auto object-center object-cover;
}
@media (max-width: 991.98px) {
  .slick--banner .slick-slide .item-image img {
    height: 350px;
  }
}
.slick--banner .slick-slide .item-single {
  @apply overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-center prose-img:object-cover;
}
@media (max-width: 991.98px) {
  .slick--banner .slick-slide .item-single {
    height: 625px;
  }
}
@media (min-width: 992px) {
  .slick--banner .slick-slide .item-single {
    aspect-ratio: inherit;
  }
}
@media (max-width: 767.98px) {
  .slick--banner .slick-slide .item-absolute {
    @apply hidden;
  }
}
.slick--banner .slick-slide .item-absolute img:nth-child(1) {
  @apply absolute -bottom-8;
}
@media (min-width: 992px) {
  .slick--banner .slick-slide .item-absolute img:nth-child(1) {
    left: -175px;
    width: 350px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .slick--banner .slick-slide .item-absolute img:nth-child(1) {
    @apply hidden;
  }
}
.slick--banner .slick-slide .item-absolute img:nth-child(2) {
  @apply absolute -right-12 lg:w-[200px];
  top: 100px;
}
.slick--banner .slick-slide .item-absolute img:nth-child(3) {
  @apply absolute lg:bottom-16 lg:right-16;
}
@media (min-width: 992px) {
  .slick--banner .slick-slide .item-absolute img:nth-child(3) {
    width: 325px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .slick--banner .slick-slide .item-absolute img:nth-child(3) {
    @apply hidden;
  }
}
.slick--product-main .slick-slide .item {
  @apply mx-auto sm:aspect-[3/4] md:h-[400px] lg:h-[400px];
}
.slick--product-main .slick-slide .item img {
  @apply w-full h-full object-contain object-center;
}
.slick--product-thumbnail .slick-slide {
  @apply m-3;
}
.slick--product-thumbnail .slick-slide.slick-current .item {
  @apply bg-white border-red;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.slick--product-thumbnail .slick-slide .item {
  @apply bg-transparent px-2 border border-transparent rounded-rg overflow-hidden;
}
.slick--gallery .slick-slide {
  @apply mx-1 lg:mx-4;
}
.slick--gallery .slick-slide .item-actions {
  @apply block aspect-[3/2] rounded-lg overflow-hidden;
}
.slick--gallery .slick-slide .item-actions > img {
  @apply w-full h-full object-cover object-center;
}
.slick--store .slick-slide {
  @apply mx-4;
}
.slick-prev, .slick-next {
  @apply overflow-hidden z-30 before:hidden;
}
.slick-prev.slick-disabled, .slick-next.slick-disabled {
  @apply opacity-50;
}
.slick--banner .slick-prev, .slick--banner .slick-next {
  @apply bg-red w-8 h-8 lg:w-12 lg:h-12 rounded-full;
  top: calc(50% - 40px);
}
.slick--banner .slick-prev:hover, .slick--banner .slick-next:hover {
  @apply bg-yellow;
}
.slick--gallery .slick-prev, .slick--gallery .slick-next {
  @apply bg-transparent top-[unset] -bottom-14 lg:-bottom-[76px] w-9 h-9 rounded-full transition ease-in-out;
}
.slick--gallery .slick-prev:hover, .slick--gallery .slick-next:hover {
  @apply bg-yellow;
}
.slick--gallery .slick-prev:hover svg, .slick--gallery .slick-next:hover svg {
  @apply fill-black;
}
.slick--banner .slick-prev {
  @apply left-3 lg:left-5;
}
.slick--product .slick-prev, .slick--store .slick-prev {
  @apply -left-2 lg:-left-4;
}
.slick--gallery .slick-prev {
  @apply left-[28vw] lg:left-[42.5%];
}
.slick--banner .slick-next {
  @apply right-3 lg:right-5;
}
.slick--product .slick-next, .slick--store .slick-next {
  @apply -right-2 lg:-right-4;
}
.slick--gallery .slick-next {
  @apply right-[28vw] lg:right-[42.5%];
}
.slick-dotted.slick-slider {
  @apply mb-0;
}
.slick-dotted.slick--gallery, .slick-dotted.slick--store {
  @apply mb-6 lg:mb-12;
}
.slick-dots > li {
  @apply mx-1 w-2 h-2;
}
.slick-dots > li.slick-active button {
  @apply bg-red !important;
}
.slick-dots > li button {
  @apply bg-white w-full h-full p-0 rounded-full overflow-hidden before:hidden;
}
.slick--banner .slick-dots {
  @apply bottom-8;
}
.slick--banner .slick-dots > li {
  @apply lg:w-4 lg:h-4;
}
.slick--product .slick-dots > li, .slick--gallery .slick-dots > li, .slick--store .slick-dots > li {
  @apply lg:w-3 lg:h-3;
}
.slick--product .slick-dots > li.slick-active, .slick--gallery .slick-dots > li.slick-active, .slick--store .slick-dots > li.slick-active {
  @apply w-3 h-3 lg:w-4 lg:h-4;
}
.slick--product .slick-dots > li button, .slick--store .slick-dots > li button {
  @apply bg-gray;
}
.slick--gallery .slick-dots {
  @apply relative lg:-bottom-12;
}
.slick--gallery .slick-dots > li button {
  @apply bg-[#C5C5C5];
}
.slick--store .slick-dots {
  @apply lg:-bottom-12;
}

.btn {
  @apply inline-flex items-center justify-center h-10 lg:h-12 px-6 lg:text-rg border border-transparent rounded-full font-bold cursor-pointer;
  text-align: center;
  outline: 0;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn svg {
  @apply h-4;
}
.btn-sm {
  @apply h-9 px-4 text-sm;
}
.btn-sm svg {
  height: 14px;
}
.btn-lg {
  @apply lg:h-14 lg:px-8 lg:text-lg;
}
.btn-primary {
  color: white;
  background-color: #FF2929 !important;
  border-color: #FF2929;
}
.btn-primary:hover {
  @apply bg-yellow border-white !important;
}
.btn-primary svg {
  fill: white;
}
.btn-secondary {
  color: black;
  background-color: #FAB600 !important;
  border-color: #FAB600;
}
.btn-secondary:hover {
  @apply bg-yellow border-white !important;
}
.btn-secondary svg {
  fill: black;
}
.btn-light {
  color: #FAB600;
  background-color: #fff !important;
  border-color: #fff;
}
.btn-light:hover {
  @apply bg-yellow border-white !important;
}
.btn-light svg {
  fill: #FAB600;
}
.btn-dark {
  color: white;
  background-color: #354856 !important;
  border-color: #354856;
}
.btn-dark:hover {
  @apply bg-yellow border-white !important;
}
.btn-dark svg {
  fill: white;
}
.btn-outline-primary, .card--product-wishlist .card-body .actions-btn, .card--product-category .card-body .actions-btn {
  background-color: transparent;
  border-color: #FF2929;
  color: #FF2929;
}
.btn-outline-primary svg, .card--product-wishlist .card-body .actions-btn svg, .card--product-category .card-body .actions-btn svg {
  fill: #FF2929;
}
.btn-outline-primary:hover, .card--product-wishlist .card-body .actions-btn:hover, .card--product-category .card-body .actions-btn:hover {
  @apply bg-yellow text-black border-black;
}
.btn-outline-primary:hover svg, .card--product-wishlist .card-body .actions-btn:hover svg, .card--product-category .card-body .actions-btn:hover svg {
  @apply fill-black;
}
.btn-ghost-primary {
  @apply h-[unset] px-0;
  background-color: transparent;
  border-color: transparent;
  color: #FF2929;
}
.btn-ghost-primary svg {
  fill: #FF2929;
}
.btn-outline-secondary {
  background-color: transparent;
  border-color: #FAB600;
  color: #FAB600;
}
.btn-outline-secondary svg {
  fill: #FAB600;
}
.btn-outline-secondary:hover {
  @apply bg-yellow text-black border-black;
}
.btn-outline-secondary:hover svg {
  @apply fill-black;
}
.btn-ghost-secondary {
  @apply h-[unset] px-0;
  background-color: transparent;
  border-color: transparent;
  color: #FAB600;
}
.btn-ghost-secondary svg {
  fill: #FAB600;
}
.btn-outline-light, .card--product-home .card-body .actions-btn {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
.btn-outline-light svg, .card--product-home .card-body .actions-btn svg {
  fill: #fff;
}
.btn-outline-light:hover, .card--product-home .card-body .actions-btn:hover {
  @apply bg-yellow text-black border-black;
}
.btn-outline-light:hover svg, .card--product-home .card-body .actions-btn:hover svg {
  @apply fill-black;
}
.btn-ghost-light {
  @apply h-[unset] px-0;
  background-color: transparent;
  border-color: transparent;
  color: #fff;
}
.btn-ghost-light svg {
  fill: #fff;
}
.btn-outline-dark {
  background-color: transparent;
  border-color: #354856;
  color: #354856;
}
.btn-outline-dark svg {
  fill: #354856;
}
.btn-outline-dark:hover {
  @apply bg-yellow text-black border-black;
}
.btn-outline-dark:hover svg {
  @apply fill-black;
}
.btn-ghost-dark {
  @apply h-[unset] px-0;
  background-color: transparent;
  border-color: transparent;
  color: #354856;
}
.btn-ghost-dark svg {
  fill: #354856;
}

.form-group {
  @apply mb-6 lg:mb-8;
}
.form-submit .wrap-captcha {
  @apply flex justify-center;
  transform: scale(0.8);
}
.form-input, .form-textarea, .form-select {
  @apply text-black font-bold lg:text-rg border-graySemi w-full px-3 py-2 rounded-sm transition ease-in-out focus:border-green focus:ring-green !important;
}
.form-input__material, .form-textarea__material, .form-select__material {
  @apply text-white bg-transparent px-0 py-1 border-r-0 border-l-0 border-t-0 border-b border-b-white rounded-none placeholder:text-white focus:border-b-yellow focus:ring-0 !important;
}
.form-material {
  @apply relative;
}
.form-material label {
  @apply absolute top-1/2 left-0 block pointer-events-none mb-0;
  transition: 0.1s ease-out;
  transform-origin: left top;
  transform: translateY(-50%);
}
.form-material input, .form-material select, .form-material textarea {
  @apply text-black text-lg bg-transparent w-full border-b border-b-black outline-0 placeholder:text-black focus:border-b-yellow focus:ring-0;
  transition: 0.1s ease-out;
}
.contact-form .form-material input, .contact-form .form-material select, .contact-form .form-material textarea {
  @apply border-b-white !important;
}
.form-material input, .form-material select {
  @apply h-12;
}
.form-material input:focus + label, .form-material input:valid + label, .form-material textarea:focus + label, .form-material textarea:valid + label {
  @apply top-0 text-xs;
  transform: translateY(-50%);
}
.form-material input:not(:placeholder-shown) + label, .form-material textarea:not(:placeholder-shown) + label {
  @apply top-0 text-xs;
  transform: translateY(-50%);
}
.contact-form .form-material input, .contact-form .form-material textarea {
  @apply text-white !important;
}
.form-material textarea:focus + label, .form-material textarea:valid + label {
  @apply -top-1 text-xs;
  transform: translateY(-50%);
}
.form-material textarea ~ label {
  @apply top-0;
}
.form-material select:focus ~ label, .form-material select:valid ~ label {
  @apply top-0 text-xs;
  transform: translateY(-50%);
}
.contact-form .form-material select {
  @apply text-white !important;
}
.contact-form .form-material select option {
  @apply text-black;
}
.form-bordered {
  @apply bg-transparent w-full h-8 border-b border-b-graySemi;
}
.form-sizing input[type=radio] {
  @apply absolute block top-0 left-0 right-0 appearance-none;
  border: none;
  outline: none;
}
.form-sizing input[type=radio]:checked ~ label {
  @apply bg-red;
}
.form-sizing input[type=radio] ~ label {
  @apply h-8 px-4 text-rg font-bold rounded-sm;
  background-color: rgba(26, 26, 26, 0.3);
}
.form-choosen input[type=radio] {
  @apply absolute block top-0 left-0 appearance-none;
  border: none;
  outline: none;
}
.form-choosen input[type=radio]:checked ~ label .dots::after {
  @apply opacity-100;
}
.form-choosen input[type=radio] ~ label {
  @apply inline-flex items-center cursor-pointer mb-0;
}
.form-choosen input[type=radio] ~ label .dots {
  @apply relative inline-flex items-center justify-center w-4 h-4 bg-transparent border border-red rounded-full overflow-hidden;
}
.form-choosen input[type=radio] ~ label .dots::after {
  content: "";
  @apply absolute block bg-red w-3 h-3 opacity-0 rounded-full transition ease-in-out;
}
.accordion--payment .form-choosen input[type=radio] ~ label {
  @apply sm:items-start;
}
.accordion--payment .form-choosen input[type=radio] ~ label .dots {
  @apply sm:mt-4;
}
.form-file input[type=file] {
  @apply absolute block top-0 left-0 appearance-none opacity-0;
  border: none;
  outline: none;
}
.form-file input[type=file] ~ label {
  @apply relative flex items-center justify-center mb-0 w-[175px] h-[175px] rounded-full overflow-hidden cursor-pointer;
}
.form-file input[type=file] ~ label .dots {
  @apply absolute inline-block w-8 h-8 bg-contain bg-no-repeat;
  background-image: url(../images/image-edit.png);
}

.navbar {
  @apply bg-white bg-opacity-30 rounded-b-xl;
  backdrop-filter: blur(15px);
}
.navbar-toggle {
  @apply w-8 h-8 border border-black rounded-sm;
}
.navbar-menu {
  @apply lg:text-lg;
}
@media (max-width: 767.98px) {
  .navbar-menu {
    @apply w-full;
  }
}
.navbar-menu__logo {
  @apply lg:order-3;
}
@media (max-width: 991.98px) {
  .navbar-menu__list {
    @apply hidden -my-1;
  }
  .navbar-menu__list > div {
    @apply py-1;
  }
}
.navbar-menu__list:nth-child(4) {
  @apply lg:order-4;
}
.navbar-menu__list:nth-child(5) {
  @apply lg:order-5;
}
.navbar-menu__list:nth-child(6) {
  @apply lg:order-6;
}
.navbar-menu__list:nth-child(7) {
  @apply lg:order-7;
}
.navbar-menu__list-item {
  @apply bg-transparent px-3 py-2 rounded-rg transition ease-in-out;
}
.active .navbar-menu__list-item {
  @apply bg-red text-yellow;
}
.navbar-menu__list-item:hover {
  @apply bg-red text-yellow;
}
.navbar .search-form input {
  @apply pr-5 !important;
}
.navbar .search-form__submit {
  @apply w-4 h-full;
}

.accordion--faq .accordion-item {
  @apply bg-white rounded-lg overflow-hidden;
}
.accordion--faq .accordion-item + .accordion-item {
  @apply mt-5;
}
.accordion--payment .accordion-item + .accordion-item {
  @apply mt-4;
}
.accordion-toggle h6 {
  @apply flex-1 pr-4 font-body font-extra;
}
.accordion--faq .accordion-toggle h6 {
  @apply lg:text-lg;
}
.accordion--payment .accordion-toggle h6 {
  @apply mb-0 text-red;
}
.accordion-toggle[aria-expanded=true] {
  background-color: inherit;
  transition-duration: 0.75s;
  transition-property: background-color, border-color;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.accordion-toggle[aria-expanded=true] .icon {
  background-image: url("../images/icon-minus-solid.svg");
}
.accordion-toggle[aria-expanded=false] {
  transition-duration: 0.75s;
  transition-property: background-color, border-color;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.accordion-toggle .icon {
  @apply inline-block w-3 h-3 bg-contain bg-center bg-no-repeat transition ease-in-out;
  background-image: url("../images/icon-plus-solid.svg");
}
.accordion-content {
  transition-duration: 0.5s;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.accordion-content[aria-hidden=true] {
  height: 0;
  opacity: 0;
  transform: translate3d(0, -0.5rem, 0);
  visibility: hidden;
}
.accordion-content[aria-hidden=false] {
  height: auto;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  visibility: visible;
}

.card {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.card--product-home {
  @apply text-white pb-5;
}
.card--product-wishlist, .card--product-category {
  @apply bg-white pb-6 lg:pb-9;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
}
.card--product:hover {
  @apply bg-white text-black;
}
.card--product:hover .card-image img {
  transform: scale(1.15);
}
.card--product:hover .card-body .btn {
  @apply border-black text-black;
}
.card--product:hover .card-body .btn svg {
  @apply fill-black;
}
.card--news-default {
  @apply text-white;
}
.card--news:hover .card-image img {
  @apply scale-110;
}
.card--news:hover .card-body > h4 {
  @apply text-yellow;
}
.card--related:hover .card-image img {
  transform: scale(1.15);
}
.card--news .card-image img {
  @apply w-full h-full object-cover object-center;
  transition: transform 0.5s ease-in-out;
}
.card--news-list .card-image {
  @apply aspect-[3/2];
}
.card--product .card-image {
  @apply mx-auto;
}
.card--product .card-image img {
  @apply transition ease-in-out;
}
.card--product-home .card-image {
  width: 100px;
}
.card--product-home .card-image img {
  width: 100px;
}
.card--product-wishlist .card-image, .card--product-category .card-image {
  width: 125px;
}
.card--product-wishlist .card-image img, .card--product-category .card-image img {
  width: 125px;
}
.card--related .card-image {
  @apply -mt-20 lg:-mt-[132px];
}
.card--related .card-image img {
  @apply mx-auto lg:h-[275px] transition ease-in-out;
  filter: drop-shadow(0 0 30px rgba(255, 255, 255, 0.6));
}
.card--product .card-body {
  @apply h-[153px];
}
.card--product .card-body h6 {
  @apply font-body font-bold text-sm line-clamp-2 mb-1;
  height: 35px;
}
.card--product-home .card-body {
  @apply px-3;
}
.card--product-wishlist .card-body, .card--product-category .card-body {
  @apply px-4 lg:px-11;
}
.card--product-category .card-body {
  @apply h-[133px];
}
.card--news .card-body > h4 {
  @apply font-body font-extra my-1 line-clamp-2 lg:h-15;
}
.card--news .card-body .excerpt p {
  @apply line-clamp-2 lg:h-[42px];
}
.card--news-list .card-body > h4 {
  @apply mb-4;
}
.card--news-list .card-body .excerpt p {
  @apply line-clamp-3 lg:h-[63px];
}
.card--transaction .card-body .content-item p {
  @apply mb-1 text-graySemi;
}
.card--transaction .card-body .content-item h6 {
  @apply font-body font-bold lg:text-lg;
}
.card--transaction .card-body .content-item::after {
  @apply bg-yellow absolute inline-block lg:top-0 lg:right-0 lg:w-[1px] lg:h-full;
  content: "";
}
@media (max-width: 991.98px) {
  .card--transaction .card-body .content-action {
    @apply pt-8;
  }
}
@media (min-width: 992px) {
  .card--transaction .card-body .content-action {
    @apply pl-12;
  }
}
.card--related .card-body > h6 {
  @apply mb-6 font-body font-bold text-lg lg:text-xl line-clamp-2 h-[50px] lg:h-15;
}
.card-body .actions-price__label {
  @apply font-bold text-xs line-through;
}
.card-body .actions-price p {
  @apply font-bold text-rg text-yellow;
}
.card-discount p {
  @apply leading-tight;
}
.card-discount span {
  @apply leading-none text-rg;
}
.card-remove > div:hover svg {
  @apply fill-red;
}
.card--product .card-wishlist {
  @apply top-4 left-4;
}
.card--product-home .card-wishlist {
  @apply bg-white top-2 left-2 flex items-center justify-center w-7 h-7 rounded-full;
}

.media {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.media--news:hover .media-image img {
  @apply scale-110;
}
.media--news:hover .media-body > h4 {
  @apply text-yellow;
}
.media--catalog {
  @apply relative overflow-hidden;
}
.media--catalog-big {
  @apply lg:col-span-2;
}
.media--catalog-big .media-absolute img:nth-child(1) {
  @apply top-20 lg:top-1/2 -left-[50px] lg:-left-[100px] h-20 lg:h-[200px];
}
@media (min-width: 992px) {
  .media--catalog-big .media-absolute img:nth-child(1) {
    transform: translateY(-50%);
  }
}
.media--catalog-big .media-absolute img:nth-child(2) {
  @apply bottom-0 lg:bottom-12 -right-[85px] lg:-right-[140px] h-[calc(150px)] lg:h-[250px];
}
.media--catalog-default:nth-of-type(even) .media-absolute img {
  @apply -bottom-[25px] lg:-bottom-10 -left-[120px] lg:-left-[200px];
}
@media (max-width: 991.98px) {
  .media--catalog-default:nth-of-type(even) .media-absolute img {
    @apply h-[150px];
  }
}
.media--catalog-default:nth-of-type(odd) .media-absolute img {
  @apply -bottom-[25px] lg:-bottom-[40px] -right-[125px] lg:-right-[185px];
}
@media (max-width: 991.98px) {
  .media--catalog-default:nth-of-type(odd) .media-absolute img {
    @apply h-[200px];
  }
}
.media--jumbotron {
  @apply relative;
}
.media--catalog .media-dialog {
  @apply relative items-center lg:h-[530px];
}
.media--catalog-default .media-dialog {
  @apply lg:px-12;
}
.media--news .media-image {
  @apply w-[100px] lg:w-[165px];
}
.media--news .media-image img {
  @apply w-full h-full object-cover object-center;
  transition: transform 0.5s ease-in-out;
}
.media--catalog .media-image img {
  @apply sm:w-1/2 w-[275px] mx-auto;
  filter: drop-shadow(0 0 30px rgba(255, 255, 255, 0.6));
}
.media--catalog-big .media-image img {
  @apply lg:w-[375px];
}
.media--jumbotron .media-image img {
  @apply sm:w-1/2 w-[300px] mx-auto -rotate-12;
  filter: drop-shadow(0 0 30px rgba(255, 255, 255, 0.6));
}
.media--cart .media-image {
  @apply sm:w-[75px] w-[150px] overflow-hidden;
}
.media--cart .media-image img {
  @apply w-full h-full object-cover object-center;
}
.media--checkout .media-image {
  @apply w-[100px] overflow-hidden;
}
.media--checkout .media-image img {
  @apply w-full h-full object-cover object-center;
}
.media--transaction .media-image .content-image {
  @apply mx-auto w-[75px] lg:w-[100px] overflow-hidden;
}
.media--transaction .media-image .content-image img {
  @apply w-full h-full object-cover object-center;
}
.media--transaction .media-image .content-title h6 {
  @apply mb-3 font-body lg:text-rg;
}
.media--transaction .media-image .content-pricing {
  @apply lg:text-rg text-graySemi;
}
.media--payment .media-image img {
  @apply max-h-[50px];
}
.media--login .media-image {
  @apply w-18 h-18 rounded-full overflow-hidden;
}
.media--login .media-image > img {
  @apply w-full h-full object-cover object-center;
}
.media--news .media-body h4 {
  @apply font-body font-bold my-1 line-clamp-1 lg:h-[30px];
}
.media--news .media-body .excerpt {
  @apply hidden lg:block;
}
.media--news .media-body .excerpt p {
  @apply line-clamp-3 lg:h-[63px];
}
@media (max-width: 991.98px) {
  .media--catalog .media-body {
    @apply pt-0;
  }
}
.media--catalog .media-body > h3 {
  @apply text-3xl lg:text-7xl;
}
@media (max-width: 991.98px) {
  .media--catalog .media-body > h3 {
    @apply mb-3;
  }
}
.media--catalog .media-body .content p {
  @apply lg:text-[18px] line-clamp-3 lg:h-[81px];
}
.media--catalog-default .media-body {
  @apply pb-12 lg:pb-0 lg:pl-8;
}
.media--catalog-default .media-body > h3 {
  @apply mb-4;
}
.media--catalog-big .media-body {
  @apply lg:flex pb-12 lg:pb-[124px] lg:pl-16;
}
.media--catalog-big .media-body .content {
  @apply lg:pl-20;
}
.media--jumbotron .media-body > h2 {
  @apply mb-3 lg:mb-6 text-3xl lg:text-7xl;
}
.media--jumbotron .media-body .content p {
  @apply lg:text-[18px] line-clamp-2 lg:h-[54px];
}
.media--jumbotron .media-body .content-tag span {
  background-color: rgba(26, 26, 26, 0.3);
}
.media--cart .media-body > h6, .media--checkout .media-body > h6 {
  @apply mb-2 font-body;
}
.media--cart .media-body .content-pricing > p, .media--checkout .media-body .content-pricing > p {
  @apply font-body font-bold;
}
.media--cart .media-body {
  @apply py-4 lg:py-6;
}
.media--cart .media-body > h6 {
  @apply font-extra lg:text-rg;
}
.media--cart .media-body .content-pricing {
  @apply mb-4;
}
.media--cart .media-body .content-pricing > p {
  @apply lg:text-xl;
}
.media--cart .media-body .content-pricing .discount {
  @apply mt-2;
}
.media--cart .media-body .content-option__flex {
  @apply relative flex items-center font-bold lg:w-[150px];
}
.media--cart .media-body .content-option__flex > .label {
  @apply relative w-19;
}
.media--cart .media-body .content-option__flex > .label::after {
  content: ":";
  @apply relative inline-block ml-auto;
}
@media (max-width: 991.98px) {
  .media--cart .media-body .content-option__flex > .label ~ div {
    @apply w-20;
  }
}
.media--checkout .media-body > h6 {
  @apply text-sm;
}
.media--checkout .media-body .content-pricing > p {
  @apply lg:text-rg;
}
.media--checkout .media-body .content-pricing .discount {
  @apply mt-1;
}
@media (max-width: 991.98px) {
  .media--transaction .media-body .content-summary {
    @apply flex items-end justify-between mt-2;
  }
}
.media--transaction .media-body .content-summary .total p {
  @apply font-bold text-black;
}
.media--payment .media-body h6 {
  @apply font-normal mb-2;
}
.media--payment .media-body p {
  @apply text-xs;
}
.media--delivery .media-body {
  @apply sm:block flex items-center justify-between;
}
.media--delivery .media-body h6 {
  @apply font-extra mb-0;
}
.media--delivery .media-body p {
  @apply font-normal text-xs;
}
.media--login .media-body h6 {
  @apply font-body font-bold mt-2;
}
.media--jumbotron .media-absolute {
  @apply hidden;
}
.media-absolute img {
  @apply absolute;
}
.media--catalog-melinjo .media-absolute img:nth-child(1) {
  @apply -bottom-[25px] lg:-bottom-10 -left-[100px] lg:-left-[140px] h-[150px] lg:h-[225px];
}
.media--catalog-spicy .media-absolute img:nth-child(1) {
  @apply -bottom-5 -right-[90px] lg:-right-[130px] h-[175px] lg:h-[250px];
}
.media--jumbotron-cassava .media-absolute img:nth-child(1) {
  @apply lg:-bottom-[30px] lg:-left-[175px] lg:h-[200px];
}
.media--jumbotron-cassava .media-absolute img:nth-child(2) {
  @apply lg:-bottom-[105px] lg:-right-[210px] lg:h-[250px];
}

.jumbotron {
  @apply lg:h-[400px] flex items-center;
  padding-top: 132px;
}
@media (min-width: 992px) {
  .jumbotron {
    padding-top: 136px;
  }
}
.jumbotron--product, .jumbotron--news {
  height: unset;
}
.jumbotron--news {
  @apply block;
}
@media (max-width: 991.98px) {
  .jumbotron-body {
    @apply py-16;
  }
}
.jumbotron--news .jumbotron-body {
  @apply py-0;
}
.jumbotron-body h2 {
  @apply font-body font-extra lg:text-8xl;
}
.jumbotron--page .jumbotron-body h2 {
  @apply text-xl;
}
.jumbotron-body .page {
  @apply lg:text-[18px];
}
.jumbotron--news .jumbotron-body {
  @apply aspect-square lg:aspect-[26/9] overflow-hidden;
}
.jumbotron--news .jumbotron-body img {
  @apply w-full h-full object-cover object-center;
}

.modal-dialog {
  @apply min-w-[calc(100%-32px)] lg:min-w-[650px];
}
.modal--store .modal-dialog {
  @apply lg:min-w-[900px];
}
.modal--alert .modal-dialog {
  @apply lg:min-w-[500px];
}
.modal--store .modal-content {
  @apply lg:h-[calc(50vh+100px)];
}
.modal-header {
  @apply absolute -top-14 right-0 lg:-top-10 lg:-right-10;
}
.modal-header [data-bs-dismiss=modal] {
  @apply bg-white flex items-center justify-center w-7 h-7 rounded-full overflow-hidden cursor-pointer;
}
.modal-body {
  @apply p-8;
}
.modal--cart .modal-body .content-detail, .modal--alert .modal-body .content-detail {
  @apply font-bold text-lg lg:text-xl leading-tight;
}
.modal--store .modal-body .content-image img {
  @apply mx-auto;
}
.modal-body .content-slider {
  @apply hidden opacity-0 transition ease-in-out;
}
.modal-body .content-slider.open {
  @apply block opacity-100;
}
.modal-body .content-slider .item-image {
  @apply aspect-[3/4] lg:h-[200px] mx-auto overflow-hidden;
}
.modal-body .content-slider .item-image > img {
  @apply w-full h-full object-cover object-center;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.25s;
}

.searchbox {
  @apply flex opacity-0 invisible;
  transform: translate3d(0, -100%, 0);
  transition-duration: 0.25s;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.searchbox-open .searchbox {
  @apply opacity-100 visible;
  transform: translate3d(0, 0, 0);
}
.searchbox-form {
  flex: 1 0 auto;
}
.searchbox-input {
  padding-right: 50px !important;
}
.searchbox-button__submit {
  width: 42px;
  height: 42px;
}

.hamburger {
  transform: translate3d(0, -100%, 0);
}
.hamburger.show {
  @apply visible opacity-100;
  transform: translate3d(0, 0, 0);
}

.dropdown {
  @apply relative;
}
.dropdown.active .dropdown-toggle .icon {
  @apply rotate-180;
  transition: transform 0.25s;
}
.dropdown.active .dropdown-item {
  @apply block;
}
.dropdown-toggle {
  @apply flex items-center cursor-pointer;
}
.dropdown-toggle .image img {
  @apply h-4;
}
.dropdown-item {
  @apply bg-white absolute hidden top-8 min-w-[100px] h-auto rounded-sm overflow-hidden z-50;
}
.dropdown-item--end {
  @apply lg:right-0;
}
.dropdown-item a {
  @apply text-black hover:text-green;
}
.dropdown-item a .image img {
  @apply h-4;
}
.navbar--fixed .dropdown-item {
  @apply top-[38px];
}

.floating {
  @apply bottom-4;
}
@screen sm {
  .floating {
    @apply left-3;
  }
}
.floating__dialog .social {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
}
@media (max-width: 991.98px) {
  .floating__dialog .social {
    @apply h-14 mr-2;
  }
}
.floating__dialog .social > a {
  @apply block my-0 lg:my-2 fill-red;
}
@media (max-width: 991.98px) {
  .floating__dialog .social > a {
    @apply mx-4;
  }
}
.floating__dialog .social > a > svg {
  @apply w-5 h-5 lg:w-4 lg:h-4;
}
@media (min-width: 992px) {
  .floating__dialog .call-link:hover img {
    transform: scale(1.5);
  }
}
.floating__dialog .call-link:hover .call-link__text {
  @apply text-sm mr-3;
  width: 142px;
}
.floating__dialog .call-link img {
  @apply h-14 lg:h-10;
}
.floating__dialog .call-link__text {
  @apply h-10;
  font-size: 0;
  width: 0;
  transition: font-size 0.3s, width 0.3s, margin 0.3s;
}

.pagination-item.current {
  @apply bg-red text-white inline-flex items-center justify-center w-8 h-8 text-lg rounded-full overflow-hidden;
}

.status::before {
  @apply relative inline-block left-0 w-4 h-4 mr-2 bg-no-repeat bg-contain;
  content: "";
}
.status--pending::before {
  background-image: url("../images/icon-pending.svg");
}
.status--process::before {
  background-image: url("../images/icon-processing.svg");
}
.status--shipped::before {
  background-image: url("../images/icon-truck.svg");
}
.status--completed::before {
  background-image: url("../images/icon-complete.svg");
}
.status--cancel::before {
  background-image: url("../images/icon-cancel.svg");
}
.status--failed::before {
  background-image: url("../images/icon-failed.svg");
}
.status--refund::before {
  background-image: url("../images/icon-refund.svg");
}

@keyframes bounceIn {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(1.25);
  }
  50% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(1);
  }
}
@keyframes fireworkLine {
  0% {
    right: 20%;
    transform: scale(0, 0);
  }
  25% {
    right: 20%;
    width: 6px;
    transform: scale(1, 1);
  }
  35% {
    right: 0;
    width: 35%;
  }
  70% {
    right: 0;
    width: 4px;
    transform: scale(1, 1);
  }
  100% {
    right: 0;
    transform: scale(0, 0);
  }
}
@keyframes fireworkPoint {
  30% {
    transform: scale(0, 0);
  }
  60% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0, 0);
  }
}
.card--product .wishlist .icon {
  @apply flex items-center justify-center w-5 h-5;
}
.product-info__desc .wishlist .icon svg {
  @apply h-5;
}
.wishlist .icon.checked .icon-none {
  @apply opacity-0;
}
.wishlist .icon.checked .icon-filled {
  @apply opacity-100;
}
.wishlist .icon.checked .icon-effect span::before {
  animation: fireworkLine 0.5s linear 0.1s;
}
.wishlist .icon.checked .icon-effect span::after {
  animation: fireworkPoint 0.5s linear 0.1s;
}
.wishlist .icon.checked svg {
  animation: bounceIn 0.5s linear;
}
.wishlist .icon .icon-filled {
  @apply absolute top-0 left-0 opacity-0;
}
.wishlist .icon .icon-effect {
  @apply absolute top-0 left-0 w-full h-full;
  transform: rotate(25deg);
}
.wishlist .icon .icon-effect span {
  @apply absolute block;
  top: 38%;
  left: 50%;
  width: 20px;
  transform-origin: 0px 2px;
}
.wishlist .icon .icon-effect span:nth-child(2) {
  transform: rotate(72deg);
}
.wishlist .icon .icon-effect span:nth-child(3) {
  transform: rotate(144deg);
}
.wishlist .icon .icon-effect span:nth-child(4) {
  transform: rotate(216deg);
}
.wishlist .icon .icon-effect span:nth-child(5) {
  transform: rotate(288deg);
}
.wishlist .icon .icon-effect span::before {
  @apply bg-red absolute block right-0;
  content: "";
  border-radius: 1.5px;
  height: 3px;
}
.wishlist .icon .icon-effect span::after {
  @apply bg-red absolute block;
  content: "";
  top: 10px;
  right: 10%;
  border-radius: 50%;
  width: 3px;
  height: 3px;
  transform: scale(0, 0);
}

.empty-image img {
  @apply mx-auto h-[200px];
}
.empty-body p {
  @apply mt-2 mx-auto lg:w-[30%];
}

.badge {
  @apply ml-2 w-5 h-5 flex items-center justify-center text-[10px] rounded-full overflow-hidden;
}
.badge-secondary {
  @apply bg-green text-white;
}

.quantity {
  @apply h-8 border-b border-b-graySemi font-bold text-center;
}
.quantity-button {
  @apply absolute flex items-center justify-center top-0 w-[14px] h-full cursor-pointer;
}
.product-cart .quantity-button {
  @apply w-[10px];
}
.product-cart .quantity-button svg {
  @apply w-[10px];
}
.quantity-button.down {
  @apply left-0;
}
.quantity-button.up {
  @apply right-0;
}

.discount span {
  @apply font-normal text-graySemi line-through;
}

.main.page-checkout {
  overflow: unset;
}

.section--transaction-detail, .section--search, .section--stray {
  padding-top: 132px;
}
@media (min-width: 992px) {
  .section--transaction-detail, .section--search, .section--stray {
    padding-top: 136px;
  }
}
@media (max-width: 991.98px) {
  .section--store {
    background-image: none !important;
  }
}
.section--login {
  padding-top: 132px;
}
@media (min-width: 992px) {
  .section--login {
    padding-top: 136px;
  }
}
@media (max-width: 991.98px) {
  .section-absolute {
    @apply hidden;
  }
}
.section-absolute .image-1 {
  @apply absolute lg:-right-12 lg:w-[200px];
  top: 160px;
}
.section-absolute .image-2 {
  @apply absolute lg:-bottom-[115px];
}
@media (min-width: 992px) {
  .section-absolute .image-2 {
    left: -225px;
    width: 500px;
  }
}
.section-dialog {
  @apply px-3 lg:px-7;
}
.section--maps .section-title {
  @apply mx-auto lg:w-[500px];
}
.section--certify .section-title {
  @apply mx-auto lg:w-[650px];
}
.section-title h2 {
  @apply text-xl lg:text-7xl;
}
.section--about .section-title h2 {
  @apply text-red;
}
.section--products .section-title h2, .section--news .section-title h2 {
  @apply text-white;
}
.section--maps .section-title h2, .section--certify .section-title h2 {
  @apply lg:text-3xl;
}
.section-title p {
  @apply lg:text-rg;
}
.section--about .section-title p {
  @apply uppercase mb-2 lg:mb-4;
  letter-spacing: 5px;
}
.section-body .post-gallery .play:hover .play-icon {
  @apply bg-yellow;
}
.section-body .post-gallery .play:hover .play-icon svg {
  @apply fill-black;
}
.section-body .post-gallery .play-icon {
  @apply bg-red mb-2;
}
.section-body .post-gallery .play-icon svg {
  @apply fill-white;
}
.section-body .post-gallery .images > div {
  @apply rounded-lg overflow-hidden will-change-transform;
}
.section-body .post-gallery .images > div img {
  @apply object-center object-cover;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .section-body .post-gallery .images > div:nth-of-type(1), .section-body .post-gallery .images > div:nth-of-type(3) {
    margin-top: -47px;
  }
}
@media (min-width: 992px) {
  .section-body .post-gallery .images > div:nth-of-type(1), .section-body .post-gallery .images > div:nth-of-type(3) {
    margin-top: -94px;
  }
}
.section-body .post-gallery .certify img {
  @apply mx-auto text-center;
}
.section-body .map .mapTooltip {
  @apply bg-white absolute p-2 rounded-sm shadow-md overflow-hidden z-30;
}
.section-body .map-tooltip {
  @apply flex items-center;
}
.section-body .map-tooltip p {
  @apply pl-2;
}
.section-body .map-tooltip img {
  @apply h-3;
}
.section--store .section-body .store-list h3 {
  @apply mb-4;
}

@media (max-width: 991.98px) {
  .footer {
    @apply pb-16 !important;
  }
}
@media (max-width: 767.98px) {
  .footer-menu .row {
    @apply text-center;
  }
}
@media (max-width: 991.98px) {
  .footer-menu__logo img {
    @apply h-[200px];
  }
}
.footer-menu__address h5 {
  @apply mb-3;
}
.footer-menu__list {
  @apply pt-1;
}
@media (min-width: 992px) {
  .footer-menu__list {
    padding-top: 37px;
  }
}
.footer-social__list svg {
  @apply fill-black h-4;
}

.post-title > h2 {
  @apply mt-2 font-body font-extra lg:text-4xl;
}
.post-detail {
  @apply prose-headings:mb-3 prose-headings:font-body prose-headings:font-bold prose-p:mb-6 prose-a:text-green prose-a:font-bold prose-ul:mb-6 prose-ol:mb-4 prose-ul:pl-4 prose-ol:pl-4 prose-li:mb-2 prose-ul:list-disc prose-ol:list-decimal prose-img:w-full prose-img:mb-6 prose-img:rounded-lg;
}
.post-detail.with-height {
  @apply h-[147px] md:h-[63px] lg:h-[63px];
  transition: height 0.3s;
  overflow: hidden;
}
.post-detail ul:last-child, .post-detail ol:last-child {
  margin-bottom: 0 !important;
}
.post-detail p:last-child {
  margin-bottom: 0 !important;
}
.post-detail iframe {
  @apply mx-auto lg:w-4/5 aspect-square lg:aspect-video mb-6 rounded-lg overflow-hidden;
}
.post-detail__point {
  @apply relative text-left;
}
.post-detail__point::before {
  content: "";
  @apply bg-red absolute top-0 left-1/2 h-full;
  width: 1px;
  transform: translateX(-50%);
}
@media (max-width: 991.98px) {
  .post-detail__point::before {
    display: none;
  }
}
.post-detail__point ul {
  @apply mb-0 pl-0 list-none;
}
.post-detail__point ul li {
  @apply relative pl-6;
}
.post-detail__point ul li::before {
  @apply absolute inline-block top-1 left-0 bg-no-repeat bg-contain w-4 h-4;
  content: "";
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAABHNCSVQICAgIfAhkiAAAAVBJREFUKFONUcFRwzAQvJskM87PHeBPMvIrpgJMBSQVECoAKiB0QAeECggV4A4SXvEkH5cAv8xkMmJ1koVtDGO9pNXtarXL1HHp0Sihfv8d4xlvt7MqTSuVEfOEtD7nLno6ikIKgjVIEUhfnOdhydNx/IT9rZy1vuwmaB1cCOl0mvFutxK+UnPgz07sBQ/NWV4fDCLe7zdtbkFagPTgSI8gLUTsJ4IQzj7ocEi5KD4ZlgvcnwG8wfCyls14PKVe79Vhb8huKmKNCAAl4BodYjjYSKA2Ay8KPAK+BlpzIGNxbL585XPL86w0Yr8cBLYlJwr7KxoOTaOJKQG5pXQ8igPM3vkIiO7h2pTil5TSImoeSGXKlSCutJ5I0/ZhKaEqZva+5V+iliQl1Nq0uC/hT8EWp74EuVNqCXfXEkGlhH8FK6Kmtaw5DFETQ1E22rw35290FrIK6V8CtQAAAABJRU5ErkJggg==");
}
.post-share {
  @apply pb-6 lg:pb-12 border-b border-b-[#CCCCCC];
}
.post-share__list svg {
  @apply fill-[#666666] h-6;
}
.post-related__title > h2 {
  @apply lg:text-3xl;
}

.stray-image img {
  @apply mx-auto;
}

.login-title h3 {
  @apply font-body font-extra mb-4;
}

.register-form {
  @apply bg-white bg-opacity-80 rounded-lg;
  backdrop-filter: blur(35px);
}
.register-image img {
  @apply w-full;
}

.profile-menu {
  @apply flex-nowrap lg:flex-wrap whitespace-nowrap lg:whitespace-normal;
}
@media (max-width: 991.98px) {
  .profile-menu {
    overflow-x: scroll;
  }
}
.profile-menu .active {
  @apply text-lg lg:text-xl text-black;
}
.profile-body__content p, .profile-body__content h6 {
  @apply text-rg;
}
@media (max-width: 991.98px) {
  .profile-body__image {
    @apply mx-auto;
  }
}
.profile-body__image img {
  @apply object-cover object-center w-full h-full;
}
.profile-body .transaction-title h5 {
  @apply font-body font-extra;
}
.profile-body .transaction-order__content .item p {
  @apply mb-1 text-graySemi;
}
.profile-body .transaction-order__content .item h6 {
  @apply font-body font-bold lg:text-lg;
}
.profile-body .transaction-order__content .item::after {
  @apply bg-yellow absolute inline-block lg:top-0 lg:right-0 lg:w-[1px] lg:h-full;
  content: "";
}
.profile-body .transaction-order__content .item:last-child::after {
  @apply hidden;
}
.profile-body .transaction-summary__content p {
  @apply relative lg:w-[250px] text-graySemi;
}
.profile-body .transaction-summary__content p::after {
  content: ":";
  @apply absolute inline-block top-0 right-0;
}
@media (max-width: 991.98px) {
  .profile-body .transaction-summary__content p {
    @apply mb-1;
  }
}
.profile-body .transaction-summary__content h6 {
  @apply font-body leading-normal lg:text-rg;
}
.profile-body .transaction-summary__content .item--summary p, .profile-body .transaction-summary__content .item--summary h6 {
  @apply lg:text-lg;
}
.profile-body .transaction-summary__content .item--summary h6 {
  @apply font-bold;
}
.profile-body .transaction-action {
  @apply lg:pt-14 border-t border-t-gray;
}

.product-info__desc .title > h2 {
  @apply mb-4 lg:text-4xl;
}
.product-info__desc .title p {
  @apply lg:text-rg;
}
.product-info__desc .sizing > label {
  @apply mb-3 font-bold;
}
.product-info__desc .pricing-info > label, .product-info__desc .pricing-quantity > label {
  @apply mb-3 font-bold;
}
.product-info__desc .pricing-info__detail > h6 {
  @apply mb-2 font-body font-bold lg:text-xl;
}
.product-info__desc .pricing-info__detail .discount span {
  @apply font-normal text-graySemi line-through;
}
.product-info__desc .actions-wishlist .icon.checked .text::after {
  content: "My Wishlist";
}
.product-info__desc .actions-wishlist .icon .text {
  @apply pl-2;
}
.product-info__desc .actions-wishlist .icon .text::after {
  content: "Add to Wishlist";
  @apply relative inline-block;
}
.product-video {
  @apply mx-auto lg:w-[900px] overflow-hidden;
}
.product-video .play {
  @apply absolute top-1/2 left-1/2;
  transform: translate(-50%, -50%);
}
.product-video .play:hover .play-icon {
  @apply bg-yellow;
}
.product-video .play:hover .play-icon svg {
  @apply fill-black;
}
.product-video .play-icon {
  @apply bg-red mb-2;
}
.product-video .play-icon svg {
  @apply fill-white;
}
.product-cart__action .item-column {
  @apply font-bold text-rg;
}
.product-cart__action .item-column > p {
  @apply w-15;
}
.product-cart__action .item-column > h6 {
  @apply flex-1 pl-2 font-body font-bold text-xl text-right;
}
.product-checkout__form {
  @apply p-4 lg:py-6 lg:px-12 text-rg;
}
.product-checkout__form h6 {
  @apply mb-6 font-body font-extra text-rg;
}
.product-checkout__form .currently-target {
  @apply h-0 opacity-0 overflow-hidden;
  transition: height 0.5s cubic-bezier(0, 1, 0, 1);
}
.product-checkout__form .currently-target.show {
  @apply h-full opacity-100;
  transition: height 0.5s ease-in-out;
}
.product-checkout__form .liner::before {
  content: "";
  @apply bg-[#DEDEDE] absolute top-1/2 left-0 w-full;
  height: 1px;
  transform: translateY(-50%);
}
.product-checkout__form .liner span {
  @apply bg-white relative px-4;
}
.product-checkout__form .newest-form__submit {
  @apply mt-6 pt-6 lg:mt-10 lg:pt-10 border-t border-t-[#DEDEDE];
}
.product-checkout__form .newest-form__submit > p {
  @apply mb-6 lg:mb-10;
}
.product-checkout__item {
  @apply sticky p-6;
  top: 136px;
}
.product-checkout__item .summary-total {
  @apply mt-4 pt-4 border-t border-t-[#DEDEDE];
}
.product-checkout__item .summary-total p {
  @apply font-bold text-rg;
}
.product-checkout__item .summary-total h6 {
  @apply font-body font-bold lg:text-xl;
}
.product-checkout__item .summary-point p, .product-checkout__item .summary-point h6 {
  @apply font-body font-bold text-rg;
}
.product-related .card--related:nth-of-type(even) .card-image {
  @apply -rotate-12;
}
.product-related .card--related:nth-of-type(odd) .card-image {
  @apply rotate-12;
}
.product-catalog > div:nth-of-type(odd) .media-image img {
  @apply -rotate-12;
}
.product-catalog > div:nth-of-type(even) .media-image img {
  @apply rotate-12;
}

.about-video__play {
  @apply rounded-lg overflow-hidden;
}
.about-video__play .play {
  @apply absolute top-1/2 left-1/2;
  transform: translate(-50%, -50%);
}
.about-video__play .play:hover .play-icon {
  @apply bg-yellow;
}
.about-video__play .play:hover .play-icon svg {
  @apply fill-black;
}
.about-video__play .play-icon {
  @apply bg-red mb-2;
}
.about-video__play .play-icon svg {
  @apply fill-white;
}
.about-video__overview > h3 {
  @apply lg:mb-6 lg:text-3xl;
}
.about-vision .media-image img {
  @apply w-[75px] lg:w-[125px];
}
.about-vision .media-body {
  @apply pl-4 lg:pl-8;
}
.about-vision .media-body > h3 {
  @apply lg:mb-6 lg:text-3xl;
}

.news-title {
  @apply flex items-center;
}
.news-title > h2 {
  @apply flex-1 pr-4 font-body font-extra lg:text-3xl;
}

.contact-list {
  @apply lg:flex lg:items-center lg:h-[600px];
}
.contact-list__place > h4 {
  @apply font-body font-extra mb-4 lg:mb-6;
}
.contact-list__place .address-item__icon {
  @apply w-4 text-center;
}
.contact-list__place .social > h5 {
  @apply font-body font-extra mb-4 lg:mb-6;
}
.contact-list__place .social-list__item {
  @apply bg-[#E1E1E1] w-8 h-8 rounded-full overflow-hidden;
}
.contact-list__place .social-list__item svg {
  @apply fill-black h-4;
}
.contact-list__place .social-list__item:hover {
  @apply bg-green;
}
.contact-list__place .social-list__item:hover svg {
  @apply fill-white;
}
.contact-list__maps {
  @apply aspect-square lg:h-[600px] lg:rounded-bl-xl overflow-hidden;
}
.contact-list__maps > * {
  @apply absolute top-0 left-0 w-full h-full;
}
.contact-list__maps::before, .contact-list__maps::after {
  content: "";
  @apply block;
}
.contact-list__maps::before {
  @apply lg:pt-[50%];
}
.contact-list__maps::after {
  @apply clear-both;
}

.store-list h4 {
  @apply font-body font-extra mb-6 lg:mb-10;
}
.store-list__item img {
  @apply max-h-13;
}

@media (max-width: 991.98px) {
  .timeline {
    background-image: none !important;
  }
}
@media (min-width: 992px) {
  .timeline {
    background-position: 0 65%;
  }
}
.timeline-grid .timeline-item:nth-of-type(even) img {
  @apply lg:order-2;
}
.timeline-grid .timeline-item:nth-of-type(even) .timeline-item__body {
  @apply lg:order-1 lg:mr-19;
}
.timeline-grid .timeline-item:nth-of-type(odd) .timeline-item__body {
  @apply lg:ml-19;
}
.timeline-item img {
  @apply mx-auto w-[150px] lg:w-[300px];
}
.timeline-item__body {
  @apply bg-white rounded-[32px] overflow-hidden;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.1);
}
.timeline-item__body h2 {
  @apply font-body font-bold lg:text-10xl text-red;
}
@media (max-width: 991.98px) {
  .timeline-item__body {
    @apply mt-4;
  }
}

.search-result__title h4 {
  @apply font-body;
}
.search-result__title h4 span {
  @apply font-extra;
}
.search-result__body .item > a {
  @apply block mt-6 pt-6 border-t border-t-graySemi;
}
.search-result__body .item > a h2 {
  @apply mb-2 lg:mb-4 font-body font-extra text-rg lg:text-xl line-clamp-1 h-[20px] lg:h-[30px];
}
.search-result__body .item > a p {
  @apply line-clamp-2 h-[39px] lg:h-[42px];
}