.search {

    &-result {

        &__title {

            h4 {
                @apply font-body;

                span {
                    @apply font-extra;
                }
            }
        }

        &__body {

            .item {

                > a {
                    @apply block mt-6 pt-6 border-t border-t-graySemi;

                    h2 {
                        @apply mb-2 lg:mb-4 font-body font-extra text-rg lg:text-xl line-clamp-1 h-[20px] lg:h-[30px];
                    }

                    p {
                        @apply line-clamp-2 h-[39px] lg:h-[42px];
                    }
                }
            }
        }
    }
}