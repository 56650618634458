.card {
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &--product {

        &-home {
            @apply text-white pb-5;
        }

        &-wishlist, &-category {
            @apply bg-white pb-6 lg:pb-9;
            box-shadow: 0px 8px 32px rgba(0, 0, 0, .08);
        }
        
        &:hover {
            @apply bg-white text-black;

            .card-image {
                
                img {
                    transform: scale(1.15);
                }
            }

            .card-body {

                .btn {
                    @apply border-black text-black;

                    svg {
                        @apply fill-black;
                    }
                }
            }
        }
    }

    &--news {

        &-default {
            @apply text-white;
        }

        &:hover {

            .card {

                &-image {

                    img {
                        @apply scale-110;
                    }
                }

                &-body {

                    > h4 {
                        @apply text-yellow;
                    }
                }
            }
        }
    }

    &--related {
        
        &:hover {

            .card-image {
                
                img {
                    transform: scale(1.15);
                }
            }
        }
    }

    &-image {

        .card--news & {

            img {
                @apply w-full h-full object-cover object-center;
                transition: transform .5s ease-in-out;
            }
        }

        .card--news-list & {
            @apply aspect-[3/2];
        }

        .card--product & {
            @apply mx-auto;
            
            img {
                @apply transition ease-in-out;
            }
        }

        .card--product-home & {
            width: 100px;

            img {
                width: 100px;
            }
        }

        .card--product-wishlist &, .card--product-category & {
            width: 125px;

            img {
                width: 125px;
            }
        }

        .card--related & {
            @apply -mt-20 lg:-mt-[132px];

            img {
                @apply mx-auto lg:h-[275px] transition ease-in-out;
                filter: drop-shadow(0 0 30px rgba(255, 255, 255, .6));
            }
        }
    }

    &-body {

        .card--product & {
            @apply h-[153px];

            h6 {
                @apply font-body font-bold text-sm line-clamp-2 mb-1;
                height: 35px;
            }
        }

        .card--product-home & {
            @apply px-3;
        }

        .card--product-wishlist &, .card--product-category &  {
            @apply px-4 lg:px-11;
        }

        .card--product-category &  {
            @apply h-[133px];
        }

        .card--news & {

            > h4 {
                @apply font-body font-extra my-1 line-clamp-2 lg:h-15;
            }

            .excerpt {

                p {
                    @apply line-clamp-2 lg:h-[42px];
                }
            }
        }

        .card--news-list & {

            > h4 {
                @apply mb-4;
            }

            .excerpt {

                p {
                    @apply line-clamp-3 lg:h-[63px];
                }
            }
        }

        .card--transaction & {
            
            .content {

                &-item {

                    p {
                        @apply mb-1 text-graySemi;
                    }

                    h6 {
                        @apply font-body font-bold lg:text-lg;
                    }

                    &::after {
                        @apply bg-yellow absolute inline-block lg:top-0 lg:right-0 lg:w-[1px] lg:h-full;
                        content: '';
                    }
                }

                &-action {

                    @include media-breakpoint-down(lg) {
                        @apply pt-8;
                    }

                    @include media-breakpoint-up(lg) {
                        @apply pl-12;
                    }
                }
            }
        }

        .card--related & {

            > h6 {
                @apply mb-6 font-body font-bold text-lg lg:text-xl line-clamp-2 h-[50px] lg:h-15;
            }
        }

        .actions {

            &-price {

                &__label {
                    @apply font-bold text-xs line-through;
                }

                p {
                    @apply font-bold text-rg text-yellow;
                }
            }

            &-btn {
                
                .card--product-home & {
                    @extend .btn-outline-light;
                }
                
                .card--product-wishlist &, .card--product-category &  {
                    @extend .btn-outline-primary;
                }
            }
        }
    }

    &-discount {

        p {
            @apply leading-tight;
        }
        
        span {
            @apply leading-none text-rg;
        }
    }

    &-remove {

        > div {

            &:hover {

                svg {
                    @apply fill-red;
                }
            }
        }
    }

    &-wishlist {

        .card--product & {
            @apply top-4 left-4;
        }

        .card--product-home & {
            @apply bg-white top-2 left-2 flex items-center justify-center w-7 h-7 rounded-full;
        }
    }
}