.contact {

    &-list {
        @apply lg:flex lg:items-center lg:h-[600px];

        &__place {

            > h4 {
                @apply font-body font-extra mb-4 lg:mb-6;
            }

            .address {

                &-item {

                    &__icon {
                        @apply w-4 text-center;
                    }
                }
            }

            .social {

                > h5 {
                    @apply font-body font-extra mb-4 lg:mb-6;
                }

                &-list {

                    &__item {
                        @apply bg-[#E1E1E1] w-8 h-8 rounded-full overflow-hidden;

                        svg {
                            @apply fill-black h-4;
                        }

                        &:hover {
                            @apply bg-green;

                            svg {
                                @apply fill-white;
                            }
                        }
                    }
                }
            }
        }

        &__maps {
            @apply aspect-square lg:h-[600px] lg:rounded-bl-xl overflow-hidden;

            > * {
                @apply absolute top-0 left-0 w-full h-full;
            }

            &::before, &::after {
                content: '';
                @apply block;
            }

            &::before {
                @apply lg:pt-[50%];
            }

            &::after {
                @apply clear-both;
            }
        }
    }
}