.store {

    &-list {

        h4 {
            @apply font-body font-extra mb-6 lg:mb-10;
        }

        &__item {

            img {
                @apply max-h-13;
            }
        }
    }
}