@keyframes bounceIn {
    0% {
        transform: scale(0);
    }
    30% {
        transform: scale(1.25);
    }
    50% {
        transform: scale(0.9);
    }
    70% {
        transform: scale(1.1);
    }
    80% {
        transform: scale(1);
    }
}

@keyframes fireworkLine {
    0% {
        right: 20%;
        transform: scale(0, 0);
    }
    25% {
        right: 20%;
        width: 6px;
        transform: scale(1, 1);
    }
    35% {
        right: 0;
        width: 35%;
    }
    70% {
        right: 0;
        width: 4px;
        transform: scale(1, 1);
    }
    100% {
        right: 0;
        transform: scale(0, 0);
    }
}
@keyframes fireworkPoint {
    30% {
        transform: scale(0, 0);
    }
    60% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(0, 0);
    }
}

.wishlist {

    .icon {

        .card--product & {
            @apply flex items-center justify-center w-5 h-5;
        }

        .product-info__desc & {

            svg {
                @apply h-5;
            }
        }

        &.checked {

            .icon {

                &-none {
                    @apply opacity-0;
                }

                &-filled {
                    @apply opacity-100;
                }

                &-effect{

                    span {

                        &::before {
                            animation: fireworkLine 0.5s linear 0.1s;
                        }

                        &::after {
                            animation: fireworkPoint 0.5s linear 0.1s;
                        }
                    }
                }
            }

            svg {
                animation: bounceIn .5s linear;
            }
        }

        .icon {

            &-filled {
                @apply absolute top-0 left-0 opacity-0;
            }

            &-effect {
                @apply absolute top-0 left-0 w-full h-full;
                transform: rotate(25deg);

                span {
                    @apply absolute block;
                    top: 38%;
                    left: 50%;
                    width: 20px;
                    transform-origin: 0px 2px;

                    &:nth-child(2) {
                        transform: rotate(72deg);
                    }

                    &:nth-child(3) {
                        transform: rotate(72*2deg);
                    }

                    &:nth-child(4) {
                        transform: rotate(72*3deg);
                    }

                    &:nth-child(5) {
                        transform: rotate(72*4deg);
                    }

                    &::before {
                        @apply bg-red absolute block right-0;
                        content: '';
                        border-radius: (3px/2);
                        height: 3px;
                    }

                    &::after {
                        @apply bg-red absolute block;
                        content: '';
                        top: 10px;
                        right: 10%;
                        border-radius: 50%;
                        width: 3px;
                        height: 3px;
                        transform: scale(0, 0);
                    }
                }
            }
        }
    }
}